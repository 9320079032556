import React, {useEffect} from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "pages/HomePage/HomePage";
import { PAGES } from "constants/pages";
import ContactPage from "pages/ContactPage/ContactPage";
import PhotovoltaicPage from "pages/PhotovoltaicPage/PhotovoltaicPage";
import ImpressumPage  from "pages/ImpressumPage/ImpressumPage";
import ServicesPage from "pages/ServicesPage/ServicesPage";
import TeamPage from "pages/TeamPage/TeamPage";
import AGBPage from "pages/AGBPage/AGBPage";
import ReferenzenPage from "pages/ReferenzenPage/ReferenzenPage";

const AppRoutes = () => {

  const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
  <Routes>
    <Route exact path={PAGES.BASE.route} element={<HomePage />} />
    <Route path={PAGES.PHOTOVOLTAIC.route} element={<PhotovoltaicPage />} />
    <Route path={PAGES.SERVICES.route} element={<ServicesPage />} />
    <Route path={PAGES.TEAM.route} element={<TeamPage />} />
    <Route path={PAGES.CONTACT.route} element={<ContactPage />} />
    <Route path={PAGES.IMPRESSUM.route} element={<ImpressumPage />} />
    <Route path={PAGES.REFERENZEN.route} element={<ReferenzenPage />} />
    <Route path={PAGES.AGB.route} element={<AGBPage />} />
    <Route path="*" element={<Navigate to={PAGES.BASE.route} />} />
  </Routes>)
};

export default AppRoutes;
