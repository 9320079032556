import React from "react";
import { ReactComponent as LinkedinIconSvg } from "assets/images/svg/linkedin.svg";

const LinkedinIcon = (props) => {
  return <LinkedinIconSvg {...props} />;
};

LinkedinIcon.propTypes = {};

export default LinkedinIcon;
