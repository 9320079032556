import Link from "components/Link/Link";
import { linkVariants } from "constants/linkVariants";
import styled from "styled-components";

export const SocialLinkContainer = styled(Link)`
  color: ${(props) =>
    props?.$variant === linkVariants.grayInitial
      ? props?.theme?.colors?.grayColor
      : props?.$variant === linkVariants.whiteInitial
      ? props?.theme?.colors?.whiteColor
      : props?.theme?.colors?.yellowColor};
  background-color: transparent;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
  &:hover {
    color: ${(props) =>
      props?.to &&
      (props?.$variant === linkVariants.yellowInitial
        ? props?.theme?.colors?.grayColor
        : props?.theme?.colors?.yellowColor)};
  }
`;
