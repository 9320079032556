/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  GalleryCategoriesContainer,
  GalleryCategoryButton,
  GalleryOverlayContainer,
  InfoIconStyle,
  OverlayIcons,
  OverlayTitle,
  ZoomIconStyle,
} from "./Gallery.styled";
import { useTranslation } from "react-i18next";
import ImageInfoDialog from "components/ImageInfoDialog/ImageInfoDialog";
import ImageCarousel from "components/ImageCarousel/ImageCarousel";

const Gallery = ({ categories, images }) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(t("common.all"));
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [carouselOpen, setCarouselOpen] = useState(false); // State for the carousel dialog
  const [selectedImageDetails, setSelectedImageDetails] = useState(null);

  const filteredImages =
    selectedCategory === "" || selectedCategory === t("common.all")
      ? images
      : images?.filter((image) => image.category === selectedCategory);

  const showOverlay = (index) => setActiveImageIndex(index);

  const hideOverlay = () => setActiveImageIndex(null);

  const handleMoreInfoClick = (image) => {
    setSelectedImageDetails(image);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedImageDetails(null);
    setDialogOpen(false);
  };

  const handleZoomClick = (index, image) => {
    setActiveImageIndex(index);
    setSelectedImageDetails(image);
    setCarouselOpen(true);
  };

  const handleCloseCarousel = () => {
    setSelectedImageDetails(null);
    setCarouselOpen(false);
  };

  return (
    <>
      {/* <GalleryCategoriesContainer>
        {categories.map((category) => (
          <GalleryCategoryButton
            style={{
              color: selectedCategory === category ? "#f39314" : "black",
            }}
            key={category}
            onClick={() => setSelectedCategory(category)}
            disableRipple
          >
            {category}
          </GalleryCategoryButton>
        ))}
      </GalleryCategoriesContainer> */}
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1000: 3 }}>
        <Masonry
          gutter={"20px"}
          style={{
            maxWidth: "1350px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {filteredImages.map((img, index) => (
            <Box
              onMouseEnter={() => showOverlay(index)}
              onMouseLeave={hideOverlay}
              key={index}
              style={{
                position: "relative",
              }}
            >
              <img loading="lazy" style={{ width: "100%", height: "100%" }} src={img.image} />
              {activeImageIndex === index && (
                <GalleryOverlayContainer>
                  <OverlayTitle>{img.installationLocation}</OverlayTitle>
                  <OverlayIcons>
                    <IconButton onClick={() => handleZoomClick(index, img)}>
                      <ZoomIconStyle />
                    </IconButton>
                    <IconButton onClick={() => handleMoreInfoClick(img)}>
                      <InfoIconStyle />
                    </IconButton>
                  </OverlayIcons>
                </GalleryOverlayContainer>
              )}
            </Box>
          ))}
        </Masonry>
      </ResponsiveMasonry>
      <ImageInfoDialog
        handleClose={handleCloseDialog}
        open={dialogOpen}
        details={selectedImageDetails}
      />
      <ImageCarousel
        open={carouselOpen}
        handleClose={handleCloseCarousel}
        images={filteredImages}
        initialIndex={activeImageIndex}
      />
    </>
  );
};

export default Gallery;