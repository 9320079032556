import Facebook from "components/Icons/FacebookIcon";
import styled from "styled-components";

export const FacebookIcon = styled(Facebook)`
  width: 15px;
  height: 15px;
  path {
    fill: #fff;
  }
`;
