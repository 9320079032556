import React from "react";
import PropTypes from "prop-types";
import Banner from "components/Banner/Banner";
import BannerBg from "../../assets/images/banner/bgImpress.jpg";
import ImpressumComponent from "components/DataComponents/ImpressumComponent/ImpressumComponent";
import { useTranslation } from "react-i18next";
import { ImpressumPageLayout } from "./ImpressumPage.styled";
import PageTitle from "components/Typography/PageTitle/PageTitle";
import { PROGRAMMIERUNG_ADDRESS, PROGRAMMIERUNG_CITY, PROGRAMMIERUNG_MAIL, PROGRAMMIERUNG_NAME, PROGRAMMIERUNG_WEBSITE } from "constants/linkConstants";
const BannerItem = [
  {
    bgImage: BannerBg,
    index: 0,
  },
];

const ImpressumPage = () => {
  const { t } = useTranslation();
  return (
    <ImpressumPageLayout>
      <Banner slideshowElements={BannerItem} />
      <PageTitle>{t("impressumPage.title")}</PageTitle>
      <ImpressumComponent title={t("impressumPage.paragraph1.title")} />
      <ImpressumComponent title={t("impressumPage.paragraph2.title")} />
      <ImpressumComponent
        title={t("impressumPage.paragraph3.title")}
        companyName={PROGRAMMIERUNG_NAME}
        companyAddress={PROGRAMMIERUNG_ADDRESS}
        companyCity={PROGRAMMIERUNG_CITY}
        companyWebsite={PROGRAMMIERUNG_WEBSITE}
        companyMail={PROGRAMMIERUNG_MAIL}
        companyPhone={null}
        isUpperCase={false}
      />
    </ImpressumPageLayout>
  );
};

ImpressumPage.propTypes = {
  children: PropTypes.node,
};

export default ImpressumPage;
