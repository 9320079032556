import React from "react";
import PropTypes from "prop-types";
import { SocialLinkContainer } from "./SocialLink.styled";
import { linkVariants } from "constants/linkVariants";

const SocialLink = (props) => {
  return (
    <SocialLinkContainer
      $variant={props?.variant}
      to={props?.to}
      className={props?.className}
    >
      {props?.children}
    </SocialLinkContainer>
  );
};

SocialLink.propTypes = {
  variant: PropTypes.oneOf([
    linkVariants.grayInitial,
    linkVariants.yellowInitial,
    linkVariants.whiteInitial,
  ]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SocialLink;
