import { Box, Button } from "@mui/material";
import styled from "styled-components";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import InfoIcon from "@mui/icons-material/Info";

export const GalleryCategoriesContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
    font-size: 16px;
  }
`;

export const GalleryCategoryButton = styled(Button)`
  margin-right: 20px;
  font-size: 14px;
  &:hover: {
    background-color: white;
    color: white;
    },
`;

export const GalleryOverlayContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(243, 147, 20, 0.8);
`;

export const OverlayTitle = styled(Box)`
  font-weight: 700;
  letter-spacing: 1px;
  color: white;
  line-height: 1.1;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
  @media (max-width: 959px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const OverlayIcons = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const ZoomIconStyle = styled(ZoomOutMapIcon)`
  padding: 1px;
  border: 1px solid white;
  border-radius: 50%;
  font-size: 36px;
  color: white;
  &:hover: {
    color: black;
    border: 1px solid black;
  },
`;

export const InfoIconStyle = styled(InfoIcon)`
  padding: 1px;
  border: 1px solid white;
  border-radius: 50%;
  font-size: 36px;
  color: white;
  &:hover: {
    color: black;
    border: 1px solid black;
  },
`;
