import { Box } from "@mui/material";
import styled from "styled-components";
import HouseImage from "assets/images/jpg/house.jpg";
import HouseHoverImage from "assets/images/jpg/house-hover.jpg";

export const HouseImageContainer = styled(Box)`
  width: 50%;
  height: 100%;
  position: absolute !important;
  opacity: 1 !important;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  transition-duration: 0.3s !important;
  background-image: url(${HouseImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    background-image: url(${HouseHoverImage});
  }
  @media (max-width: 600px) {
    height: 300px;
    width: 100%;
    top: initial;
    left: 0;
    /* margin: 0 auto; */
  }
`;
