import React from "react";
import {
  AGBContentContainer,
  AGBPageLayout,
  SingleParagraphTitle,
  SingleParagraphText,
  AGBTextWrapper,
} from "./AGBPage.styled";
import BannerBg from "../../assets/images/banner/bgImpress.jpg";
import Banner from "components/Banner/Banner";
import PageTitle from "components/Typography/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";

const BannerItem = [
  {
    bgImage: BannerBg,
    index: 0,
  },
];

const content = [
  {
    title: "1. Geltungsbereich",
    paragraphs: [
      {
        content:
          "1.1 Die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB) sind integrierender Bestandteil sämtlicher Verträge mit Kunden der MegaWatt Solar AG mit Sitz in Baar (nachfolgend \"MegaWatt Solar\" genannt).",
      },
      {
        content:
          "1.2 Abweichende Bestimmungen sowie die Geltung von Kunden-AGB bedürfen der schriftlichen Vereinbarung.",
      },
      {
        content:
          "1.3 Die AGB finden Anwendung auf Kauf-, Liefer- und Werkverträge.",
      },
      { content: "1.4 Reine Dienstleistungen unterliegen dem Auftragsrecht." },
    ],
  },
  {
    title: "2. Leistungen MegaWatt Solar",
    paragraphs: [
      {
        content:
          "MegaWatt Solar verpflichtet sich zur Erbringung von Leistungen und Lieferungen in hoher Qualität. Zudem gewährleistet MegaWatt Solar eine sorgfältige Auswahl, Ausbildung und fachmännische Arbeitsweise seiner Mitarbeitenden sowie die gewissenhafte Auswahl von Lieferanten, Zulieferern und sonstigen Partnern",
      },
    ],
  },
  {
    title: "3.Lieferfristen und -termine",
    paragraphs: [
      {
        content:
          "3.1 MegaWatt Solar strebt die Einhaltung der vorab berechneten Lieferfristen und -termine an. Überschreitungen berechtigen den Kunden weder zum Vertragsrücktritt noch zur Geltendmachung von Ersatzansprüchen.",
      },
      {
        content:
          "3.2 Rohmaterialmängel, Betriebsstörungen, Lieferstörungen der Lieferanten und Fälle höherer Gewalt entbinden MegaWatt Solar für die Dauer der Behinderung von der Lieferverpflichtung. Ein Anspruch auf Schadenersatz oder Vertragsrücktritt seitens des Kunden besteht nicht.",
      }
    ],
  },
  {
    title: "4.Projektänderungen",
    paragraphs: [
      {
        content:
          "4.1 MegaWatt Solar erstellt Angebote basierend auf den vom Kunden mitgeteilten Parametern und auf Basis einer Grobanalyse des Gebäudes ohne verbindliche technische Prüfung. Sollte die Leistung von MegaWatt Solar erschwert oder verunmöglicht werden aus Gründen, die bei der Grobanalyse nicht erkennbar waren (bei Photovoltaik namentlich: asbesthaltige Materialien, unübliche Dachkonstruktion, spezielle Bauzone, spezielle & neue Netzanforderungen, o.ä.), so ist MegaWatt Solar berechtigt, entschädigungslos vom Vertrag zurückzutreten und dem Kunden ein neues, revidiertes Angebot zuzustellen. Der Kunde kann diesfalls wählen, ob er die revidierte Offerte annehmen oder keinen neuen Vertrag eingehen möchte. Angefallene Arbeiten und Aufwendungen von MegaWatt Solar oder Unterakkordanten werden bei Abbruch der Arbeit dem Kunden in Rechnung gestellt.",
      },
      {
        content:
          "4.2 MegaWatt Solar übernimmt keine Gewährleistung für die Richtigkeit und Vollständigkeit der mit der Offerte ausgehändigten Datenblätter (einschließlich technischer Angaben) der Lieferanten.",
      },
      {
        content:
          "4.3 Sollten offerierte Produkte aus Gründen des Herstellers nicht mehr lieferbar sein, werden die Mehr- oder Minderkosten für Ersatzprodukte dem Kunden übertragen.",
      }
    ],
  },
  {
    title: "5. Bewilligungen und Förderanträge",
    paragraphs: [
      {
        content:
          "5.1 Sofern das Anfordern von Förderbeiträgen (z.B. kantonale und kommunale Förderbeiträge usw.) als Bestandteil der Leistungen der MegaWatt vereinbart wird, tritt die MegaWatt als bevollmächtigte Vertreterin des Kunden gegenüber Behörden auf.",
      },
      {
        content:
          "5.2 Zwischen der MegaWatt und dem Kunden (Grund- oder Gebäudeeigentümer) wird – sofern Leistungen gemäß Ziffer 5.1 vereinbart wurden – eine schriftliche Vollmachtserklärung separat erstellt und unterzeichnet.",
      },
      {
        content:
          "5.3 Die MegaWatt führt in einem solchen Fall die notwendigen Anmelde- und Gesuchsverfahren für den Kunden aus und begleitet diese.",
      },
      {
        content:
          "5.4 Die MegaWatt übernimmt keine Garantie für die Erteilung und Genehmigung von Förderbeiträgen oder Bewilligungen.",
      },
      {
        content:
          "5.5 Die von der MegaWatt gestellten Rechnungen sind geschuldet, auch wenn die Genehmigungs- oder Bewilligungsverfahren durch die Behörden noch nicht abgeschlossen sind oder, wenn Förderbeiträge oder Bewilligungen durch Behörden verweigert werden.",
      },
      {
        content:
          "5.6 Die Arbeit von MegaWatt Solar beginnt nach Erteilung der notwendigen Bewilligungen und Entscheide über Förderbeiträge. Auf Wunsch des Kunden kann die Arbeit bereits vor Erteilung der Bewilligungen aufgenommen.",
      },
      {
        content:
          "5.7 Sollten Bewilligungen oder Entscheide über Förderbeiträge nachträglich negativ ausfallen, ist der Kauf- oder Werkpreis dennoch im vereinbarten Umfang zu entrichten.",
      },
      {
        content:
          "5.8 Angaben und Berechnungen zur Wirtschaftlichkeit basieren auf Annahmen und Erfahrungswerten. MegaWatt Solar übernimmt keine Gewährleistung für Fehlannahmen und Fehler jeglicher Art in wirtschaftlichen Berechnungen.",
      },
    ],
  },
  {
    title: "6. Preise / Zahlungskonditionen",
    paragraphs: [
      {
        content:
          "6.1 Die im Angebot genannte Vorauszahlung wird bei Vertragsabschluss fällig. Die Schlusszahlung erfolgt nach Inbetriebnahme der Anlage. Weitere Vorauszahlungen nach Montagebeginn und vor Inbetriebnahme bleiben vorbehalten.",
      },
      {
        content:
          "6.2 Für die Leistungen und Lieferungen der MegaWatt gelten verbindlich die im unterzeichneten Angebot genannten Preise. Die jeweils geltende Mehrwertsteuer trägt der Kunde.",
      },
      {
        content:
          "6.3 Lieferung, Montage und weitere vertragliche Leistungen erfolgen erst nach Eingang der Vorauszahlung bei MegaWatt Solar. Bei Zahlungsverzug kann MegaWatt Solar die Montage unterbrechen, ohne dass dies den Kunden von den vertraglichen Verpflichtungen entbindet.",
      },
      {
        content:
          "6.4 Bei Zahlungsverzug wird der gesetzliche Verzugszins erhoben.",
      },
      {
        content:
          "6.5 Die gelieferte Ware bleibt bis zur vollständigen Bezahlung im Eigentum von MegaWatt Solar. MegaWatt Solar ist berechtigt, den Eigentumsvorbehalt im Register anzumelden.",
      }
    ],
  },
  {
    title: "7. Gewährleistung",
    paragraphs: [
      {
        content:
          "7.1 Allfällige Mängel meldet der Kunde schriftlich bis spätestens 14 Tage nach Lieferung oder Montage. Nach dieser Frist gelten Waren und Anlagen als abgenommen, ausgenommen sind versteckte Mängel, die bei sorgfältiger Überprüfung nicht erkennbar waren.",
      },
      {
        content:
          "7.2 Bei rechtzeitiger Mängelrüge nach 7.1 leistet MegaWatt Solar für versteckte Mängel zwei Jahre Gewähr für den kostenpflichtigen betriebsfertigen Austausch des defekten Produkts. Bei geringfügigen Mängeln kann der Kunde eine Preisminderung im Umfang der Minderleistung verlangen.",
      },
      {
        content:
          "7.3 Die Gewährleistung für zugekaufte Komponenten beschränkt sich auf die tatsächlichen Garantieleistungen der Lieferanten. Lehnen Lieferanten Garantieleistungen ab oder sind insolvent oder nicht mehr existent, entfällt die Garantie. Weitere Gewährleistungen sind ausgeschlossen.",
      },
      {
        content:
          "7.4 Die Gewährleistung erlischt, wenn der Kunde eigenhändig oder durch Dritte Änderungen, Reparaturen oder Instandsetzungsarbeiten an der Ware oder Anlage vornimmt.",
      },
      {
        content:
          "7.5 Die Gewährleistung für einzelne Teile gilt nur, wenn die installierte Anlage als Ganzes den garantierten Systemwirkungsgrad nicht erreichen kann.",
      }
    ],
  },
  {
    title: "8. Weitere Bestimmungen",
    paragraphs: [
      {
        content:
          "8.1 Nutzen und Gefahr gehen mit erfolgter Lieferung an den Kunden über.",
      },
      {
        content:
          "8.2 MegaWatt Solar und der Kunde verpflichten sich, sich gegenseitig über alle vertragsrelevanten Änderungen zu informieren.",
      },
      {
        content:
          "8.3 Bei Personengesellschaften als Kunden haften die Gesellschafter gegenüber MegaWatt Solar als Solidarschuldner.",
      },
      {
        content:
          "8.4 Ungültige oder unwirksame Bestimmungen dieser AGB berühren nicht die Gültigkeit der übrigen Vertragsbestimmungen.",
      },
      {
        content:
          "8.5 MegaWatt Solar behält sich Änderungen der AGB vor. Änderungen werden dem Kunden mitgeteilt und gelten ohne schriftlichen Widerspruch innerhalb eines Monats als genehmigt.",
      }
    ],
  },
  {
    title: "9. Anwendbares Recht und Gerichtsstand",
    paragraphs: [
      {
        content:
          "9.1 Der Vertrag unterliegt dem Schweizer OR, unter Ausschluss von SIA-Normen.",
      },
      {
        content:
          "9.2 Anwendbar ist ausschließlich Schweizerisches materielles Recht.",
      },
      {
        content:
          "9.3 Zuständig für alle Streitigkeiten ist das Gericht am Sitz von MegaWatt Solar in Baar. Sind die Voraussetzungen erfüllt, ist das Handelsgericht des Kantons Zug zuständig.",
      },
    ],
  },
];

const AGBPage = () => {
  const { t } = useTranslation();
  return (
    <AGBPageLayout>
      <Banner slideshowElements={BannerItem} />
      <AGBContentContainer>
        <PageTitle>{t("agbPage.title")}</PageTitle>
        {content.map((single) => (
          <AGBTextWrapper key={single.title}>
            <SingleParagraphTitle>{single.title}</SingleParagraphTitle>
            {single.paragraphs.map((paragraph, index) => (
              <SingleParagraphText key={index}>
                {paragraph.content}
              </SingleParagraphText>
            ))}
          </AGBTextWrapper>
        ))}
      </AGBContentContainer>
    </AGBPageLayout>
  );
};

export default AGBPage;
