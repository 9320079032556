import React from "react";
import { PAGES } from "./pages";
import { ReactComponent as Aufdach } from "assets/images/svg/aufdach.svg";
import { ReactComponent as Flachdach } from "assets/images/svg/flachdach.svg";

export const headerNavigationConstants = [PAGES.PHOTOVOLTAIC, PAGES.CONTACT, PAGES.IMPRESSUM, PAGES.SERVICES, PAGES.TEAM];
export const informationNavigationConstants = [];
export const bannerNavigationConstants = [
  {
    title: "bottomNavigation.item1.title",
    route: PAGES.PHOTOVOLTAIC.route + "#aufdach",
    icon: <Aufdach />,
  },
  {
    title: "bottomNavigation.item3.title",
    route: PAGES.PHOTOVOLTAIC.route + "#flachdach",
    icon: <Flachdach />,
  },
  
];
