import styled from "styled-components";
import { FooterItemsContainer } from "../Footer.styled";
import Link from "components/Link/Link";
import Solarprofis from "assets/images/jpg/solarprofis.jpg";

export const SocialSection = styled(FooterItemsContainer)`
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
export const SolarprofisImage = styled(Link)`
  width: 74px;
  height: 45px;
  background-image: url(${Solarprofis});
  background-size: cover;
`;
