import React from "react";
import PropTypes from "prop-types";
import PageLayout from "layouts/PageLayout";
import Banner from "components/Banner/Banner";
import FourParagraphsComponent from "components/DataComponents/FourParagraphsComponent/FourParagraphsComponent";
import { Trans } from "react-i18next";
import PhotoVoltaicComponent from "components/DataComponents/PhotoVoltaicComponent/PhotoVoltaicComponent";
// import PhotoVoltaicImage1 from "assets/images/jpg/photovoltaic1.jpg";
import PhotoVoltaicImage2 from "assets/images/jpg/photovoltaic2.jpg";
import PhotoVoltaicImage3 from "assets/images/jpg/photovoltaic3.jpg";
import PhotoVoltaicImage4 from "assets/images/jpg/photovoltaic4.jpg";
import BannerBg from "assets/images/jpg/bannerBg1.jpg";

const PhotovoltaicPage = () => {
  return (
    <PageLayout>
      <Banner
        slideshowElements={[
          {
            bgImage: BannerBg,
            text: <Trans i18nKey="photovoltaicPage.banner.title" />,
            index: 0,
          },
        ]}
      />
      <FourParagraphsComponent
        biggerText
        biggerHeader
        headerParagraphTitle={
          <Trans i18nKey="photovoltaicPage.paragraph1.title" />
        }
        headerParagraphText={
          <Trans i18nKey="photovoltaicPage.paragraph1.text" />
        }
      />
      {/* <PhotoVoltaicComponent
        title={<Trans i18nKey="photovoltaicPage.paragraph2.title" />}
        imgSrc={PhotoVoltaicImage1}
        paragraphTitle={
          <Trans i18nKey="photovoltaicPage.paragraph2.subtitle" />
        }
        paragraphText={<Trans i18nKey="photovoltaicPage.paragraph2.text" />}
        dropDownData={Array(1)
          .fill(null, 0)
          .map((item, index) => ({
            dropdownTitle: (
              <Trans
                i18nKey={"photovoltaicPage.paragraph2.dropDownData.${index}.dropdownTitle"}
              />
            ),
            dropdownText: (
              <Trans
                i18nKey={"photovoltaicPage.paragraph2.dropDownData.${index}.dropdownText"}
              />
            ),
          }))}
      /> */}
      <PhotoVoltaicComponent
        id="aufdach"
        title={<Trans i18nKey="photovoltaicPage.paragraph3.title" />}
        imgSrc={PhotoVoltaicImage2}
        paragraphTitle={
          <Trans i18nKey="photovoltaicPage.paragraph3.subtitle" />
        }
        paragraphText={<Trans i18nKey="photovoltaicPage.paragraph3.text" />}
        dropDownData={Array(1)
          .fill(null, 0)
          .map((item, index) => ({
            dropdownTitle: (
              <Trans
                i18nKey={`photovoltaicPage.paragraph3.dropDownData.${index}.dropdownTitle`}
              />
            ),
            dropdownText: (
              <Trans
                i18nKey={`photovoltaicPage.paragraph3.dropDownData.${index}.dropdownText`}
              />
            ),
          }))}
      />
      <PhotoVoltaicComponent
        id="flachdach"
        title={<Trans i18nKey="photovoltaicPage.paragraph4.title" />}
        imgSrc={PhotoVoltaicImage3}
        reverse
        light
        paragraphTitle={
          <Trans i18nKey="photovoltaicPage.paragraph4.subtitle" />
        }
        paragraphText={<Trans i18nKey="photovoltaicPage.paragraph4.text" />}
        dropDownData={Array(1)
          .fill(null, 0)
          .map((item, index) => ({
            dropdownTitle: (
              <Trans
                i18nKey={`photovoltaicPage.paragraph4.dropDownData.${index}.dropdownTitle`}
              />
            ),
            dropdownText: (
              <Trans
                i18nKey={`photovoltaicPage.paragraph4.dropDownData.${index}.dropdownText`}
              />
            ),
          }))}
      />
      <PhotoVoltaicComponent
        title={<Trans i18nKey="photovoltaicPage.paragraph5.title" />}
        imgSrc={PhotoVoltaicImage4}
        paragraphTitle={
          <Trans i18nKey="photovoltaicPage.paragraph5.subtitle" />
        }
        paragraphText={<Trans i18nKey="photovoltaicPage.paragraph5.text" />}
        dropDownData={Array(1)
          .fill(null, 0)
          .map((item, index) => ({
            dropdownTitle: (
              <Trans
                i18nKey={`photovoltaicPage.paragraph5.dropDownData.${index}.dropdownTitle`}
              />
            ),
            dropdownText: (
              <Trans
                i18nKey={`photovoltaicPage.paragraph5.dropDownData.${index}.dropdownText`}
              />
            ),
          }))}
      />
    </PageLayout>
  );
};

PhotovoltaicPage.propTypes = {
  children: PropTypes.node,
};

export default PhotovoltaicPage;
