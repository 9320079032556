import React from "react";
import { StyledEngineProvider } from "@mui/material";
import Header from "components/Header/Header";
import { LANGUAGE } from "constants/localStorage";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authScopeStringGetHelper } from "util/authScopeHelpers";
import AppRoutes from "./AppRoutes";
import SocialHeader from "components/SocialHeader/SocialHeader";
import Footer from "components/Footer/Footer";
import ProgressBar from "components/ProgressBar/ProgressBar";

const App = () => {
  const { i18n } = useTranslation();
  useBreadcrumbs();

  useEffect(() => {
    const lang = authScopeStringGetHelper(LANGUAGE);

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Megawatt Solar</title>
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ToastContainer bodyClassName="ToastBody" />
        <SocialHeader />
        <Header />
        <ProgressBar />
        <AppRoutes />
        <Footer />
      </StyledEngineProvider>
    </>
  );
};

export default App;
