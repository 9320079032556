export const PHONE_NUMBER = "078 911 73 79";
export const MAIL = "info@megawattsolar.ch";
export const FACEBOOK_PAGE = "https://www.facebook.com/profile.php?id=61550912115463";
export const LINKEDIN_PAGE = "https://www.linkedin.com/company/megawatt-solar-ag/";
export const INSTAGRAM_PAGE = "https://www.instagram.com/megawatt_solar_ag";
export const WEBSITE = "http://www.megawattsolar.ch/";
export const COMPANY_NAME = "MegaWatt Solar AG";
export const COMPANY_ADDRESS = "Blegistrasse 7";
export const COMPANY_CITY = "6340 Baar";
export const COMPANY_LAT = 47.21608587941406;
export const COMPANY_LNG = 8.570921871164206;

export const PROGRAMMIERUNG_WEBSITE = "https://www.tremium.net/";
export const PROGRAMMIERUNG_NAME = "TREMIUM software";
export const PROGRAMMIERUNG_ADDRESS = "Obrenoviceva 23/1";
export const PROGRAMMIERUNG_CITY = "18000 Nis";
export const PROGRAMMIERUNG_MAIL = "office@tremium.net";
