import { Box } from "@mui/material";
import TwoParagraphComponent from "components/DataComponents/TwoParagraphComponent/TwoParagraphComponent";
import {
  ParagraphsContentContainer,
  SingleParagraphContainer,
} from "components/DataComponents/TwoParagraphComponent/TwoParagraphComponent.styled";
import styled from "styled-components";

export const TeamPageBanner = styled(TwoParagraphComponent)`
  padding-bottom: 65px;
  & ${ParagraphsContentContainer} {
    & > ${SingleParagraphContainer} {
      max-width: 600px;
    }
    @media (max-width: 600px) {
      gap: 0;
    }
  }
  @media (max-width: 600px) {
    padding-top: 140px;
    gap: 0;
  }
`;

export const TeamContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 60px;
`;

export const TeamRowContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const TeamRowItem = styled(Box)`
  width: 20%;
  padding-right: 20px;
  @media (max-width: 959px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
  }
`;
