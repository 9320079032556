import Link from "components/Link/Link";
import styled from "styled-components";
// Replace with styled.img when there is logo
export const LogoContainer = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => props?.theme?.colors?.textColor};
  & > svg {
    height: 60px;
    width: 320px;
  }
  & .str0 {
    stroke: #fefefe;
    stroke-width: 14.52;
    stroke-miterlimit: 2.61313;
  }
  & .fil2 {
    fill: #f39314;
    fill-rule: nonzero;
  }
  & .fil1 {
    fill: #f39314;
    fill-rule: nonzero;
  }
  & .fil0 {
    fill: #243e79;
    fill-rule: nonzero;
  }
`;
