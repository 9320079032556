import React from "react";
import PropTypes from "prop-types";
import {
  FooterContainer,
  FooterHalfContainer,
  FooterSection,
  LogoImage,
} from "./Footer.styled";
import ContactSection from "./ContactSection/ContactSection";
import NavigationSection from "./NavigationSection/NavigationSection";
import InformationSection from "./InformationSection/InformationSection";
import { PAGES } from "constants/pages";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterHalfContainer>
        <FooterSection>
          <LogoImage to={PAGES.BASE.route}/>
        </FooterSection>
        <ContactSection />
      </FooterHalfContainer>
      <FooterHalfContainer>
        <NavigationSection />
        <InformationSection />
      </FooterHalfContainer>
    </FooterContainer>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
