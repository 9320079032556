import React from "react";
import PropTypes from "prop-types";
import {
  FooterItem,
  FooterItemsContainer,
  FooterSection,
  FooterTitle,
} from "../Footer.styled";
import { useTranslation } from "react-i18next";
import { SocialSection } from "./InformationSection.styled";
import FacebookButtonLink from "components/SocialLinks/FacebookButtonLink/FacebookButtonLink";
import LinkedinButtonLink from "components/SocialLinks/LinkedinButtonLink/LinkedinButtonLink";
import InstagramButtonLink from "components/SocialLinks/InstagramButtonLink/InstagramButtonLink";
import { linkVariants } from "constants/linkVariants";
import { PAGES } from "constants/pages";

const InformationSection = () => {
  const { t } = useTranslation();
  return (
    <FooterSection>
      <FooterTitle>{t("informations.title")}</FooterTitle>
      {/* <FooterItemsContainer>
        {informationNavigationConstants?.map?.((singleRoute) => (
          <FooterItem
            key={singleRoute?.route}
            to={singleRoute?.route}
            variant={linkVariants.whiteInitial}
          >
            {singleRoute?.title}
          </FooterItem>
        ))}
      </FooterItemsContainer> */}
      <FooterItemsContainer>
        <FooterItem to={"/agb"} variant={linkVariants.whiteInitial}>
          {PAGES.AGB.title}
        </FooterItem>
      </FooterItemsContainer>
      <SocialSection>
        <FacebookButtonLink />
        <LinkedinButtonLink />
        <InstagramButtonLink />
      </SocialSection>
    </FooterSection>
  );
};

InformationSection.propTypes = {
  children: PropTypes.node,
};

export default InformationSection;
