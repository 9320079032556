import { Box } from "@mui/material";
import Link from "components/Link/Link";
import styled from "styled-components";

export const AutoSlideshowContainer = styled(Box)`
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 100px;
  margin-top: 30px;
  overflow: hidden;
`;
export const AutoSlideshowSingleContainer = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  margin: auto 0;
  transition: all 0.5s ease-in-out, opacity 0s 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  left: ${(props) => props?.$positionIndex * 25}%;
  opacity: ${(props) =>
    (props?.$positionIndex < 0 || props?.$positionIndex === 5) && 0};
  width: 20%;
  min-width: 20%;
  max-width: 20%;
  & svg {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    & path {
      fill: ${(props) => props?.theme?.colors?.whiteColor} !important;
    }
  }
  &:hover {
    & path {
      fill: ${(props) => props?.theme?.colors?.yellowColor} !important;
    }
  }
  @media (max-width: 959px) {

    left: ${(props) => props?.$positionIndex * 34}%;
    width: 32%;
    min-width: 32%;
    max-width: 32%;
  }
  @media (max-width: 600px) {
    left: ${(props) => props?.$positionIndex * 50}%;
    width: 47%;
    min-width: 47%;
    max-width: 47%;
  }
`;
