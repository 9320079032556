// import Banner from "components/Banner/Banner";
import PageLayout from "layouts/PageLayout";
import React from "react";
// import BannerBg from "assets/images/jpg/bannerBg1.jpg";
import { Trans } from "react-i18next";
// import FourParagraphsComponent from "components/DataComponents/FourParagraphsComponent/FourParagraphsComponent";
// import TeamComponent from "components/DataComponents/TeamComponent/TeamComponent";
import Team1 from "../../assets/images/jpg/team1.jpg";
// import Team2 from "../../assets/images/jpg/team2.jpg";
// import Team3 from "../../assets/images/jpg/team3.jpg";
// import Team4 from "../../assets/images/jpg/team4.jpg";
// import Team5 from "../../assets/images/jpg/team5.jpg";
// import Team6 from "../../assets/images/jpg/team6.jpg";
//import TeamBg from "assets/images/jpg/teamBg.jpg";
import {
  TeamContainer,
  TeamPageBanner,
  TeamRowContainer,
  TeamRowItem,
} from "./TeamPage.styled";
import NormalWeightText from "components/Typography/HOC/NormalWeightText";
import BoldText from "components/Typography/HOC/BoldText";
import useDevice from "hooks/useDevice";
import {
  TeamContentContainer,
  SingleParagraphTitle,
  SingleParagraphText,
} from "components/DataComponents/TeamComponent/TeamComponent.styled";
import { HeaderParagraphTitle } from "components/DataComponents/FourParagraphsComponent/FourParagraphsComponent.styled";
import { HeaderParagraphContainer } from "components/DataComponents/TwoParagraphComponent/TwoParagraphComponent.styled";
import TeamMemberCard from "components/Cards/TeamMemberCard.js/TeamMemberCard";
//import { primaryThemeColors } from "themes/primaryTheme/primaryThemeColors";

const teams = [
  {
    name: "Geschäftsleitung",
    description: "",
    teamName: "",
    members: [
      {
        name: "Vladislav",
        surname: "",
        Image: Team1,
        position: "Geschäftsleiter",
      },
    ],
  },
  {
    name: "Innendienst",
    description: "",
    teamName: "",
    members: [
      {
        name: "Andjela",
        surname: "",
        Image: "",
        position: "",
      },
    ],
  },
  {
    name: "Verkauf",
    description:
      "Unsere Verkaufsberater sind Experten im Bereich Energiemanagement.Sie bieten maßgeschneiderte Lösungen für Ihre individuellen Bedürfnisse. Mit ihrer langjährigen Erfahrung und ihrer sympathischen Art schaffen sie eine offene und transparente Kundenbeziehung. Wir helfen Ihnen dabei, Ihre Energieeffizienz zu maximieren und Kosten zu senken.",
    teamName: "",
    members: [
      {
        name: "Jan - Verkaufsleiter",
        surname: "",
        Image: "",
        position: "",
      },
      {
        name: "Valerio",
        surname: "",
        Image: "",
        position: "",
      },
    ],
  },
  {
    name: "Projektleiter",
    description: "",
    teamName: "",
    members: [
      {
        name: "Hendrik",
        surname: "",
        Image: "",
        position: "",
      },
    ],
  },
  {
    name: "Solarteure und Solaranlagenmonteure",
    description:
      "Unsere Fachkräfte für Solartechnik sind Ihr zuverlässiger Partner für die Installation von Solaranlagen. Mit ihrer Fachkompetenz, langjährigen Erfahrung und einem Auge für Details stellen sie sicher, dass Ihre Anlage sauber, sicher und effizient installiert wird.",
    teamName: "Team Alex",
    members: [
      {
        name: "Alexandru",
        surname: "",
        Image: "",
        position: "",
      },
      {
        name: "Sebastian",
        surname: "",
        Image: "",
        position: "",
      },
      {
        name: "Daniil",
        surname: "",
        Image: "",
        position: "",
      },
    ],
  },
];
const TeamPage = () => {
  const { isMobile } = useDevice();
  return (
    <PageLayout>
      <TeamPageBanner
        light={!isMobile}
        headerParagraphTitle={
          <Trans
            i18nKey="teamPage.paragraph1.title"
            components={{ span: <NormalWeightText /> }}
          />
        }
        headerParagraphText={<Trans i18nKey="teamPage.paragraph1.text" />}
        leftParagraphText={
          <Trans
            i18nKey="teamPage.paragraph2.text"
            components={{ span: <BoldText /> }}
          />
        }
        rightParagraphText={
          <Trans
            i18nKey="teamPage.paragraph3.text"
            components={{ span: <BoldText /> }}
          />
        }
        //bgImage={TeamBg}
      />
      <TeamContentContainer>
        <HeaderParagraphContainer>
          <HeaderParagraphTitle>
            <Trans i18nKey="teamPage.teams.title" />
          </HeaderParagraphTitle>
        </HeaderParagraphContainer>
        {teams.map((team) => (
          <TeamContainer key={team.name}>
            <SingleParagraphTitle style={{marginBottom: !team.description && 16}}>{team.name}</SingleParagraphTitle>
            {team.description && (
              <SingleParagraphText>{team.description}</SingleParagraphText>
            )}
            {team.teamName && (
              <SingleParagraphText
                style={{
                  borderBottom: "1px solid #c7c7c7",
                  marginTop: 0,
                }}
              >
                {team.teamName}
              </SingleParagraphText>
            )}
            <TeamRowContainer>
              {team.members.map((member) => (
                <TeamRowItem key={member.name}>
                  <TeamMemberCard member={member} />
                </TeamRowItem>
              ))}
            </TeamRowContainer>
          </TeamContainer>
        ))}
      </TeamContentContainer>
    </PageLayout>
  );
};

export default TeamPage;
