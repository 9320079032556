import React from "react";
import PropTypes from "prop-types";
import {
  DrawerItemsContainer,
  DrawerSingleItemContainer,
  DrawerSingleItemIcon,
  DrawerSingleItemTitle,
  HeaderNewDrawerContainer,
} from "./HeaderNewDrawer.styled";
import { headerNavigationConstants } from "constants/navigationConstants";
import { useTranslation } from "react-i18next";
import { isInRoute } from "util/routeHelpers";
import { useLocation } from "react-router-dom";

const HeaderNewDrawer = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <HeaderNewDrawerContainer
      isOpened={props?.isOpened}
      onClose={props?.onClose}
      anchor="right"
    >
      <DrawerItemsContainer>
        {headerNavigationConstants?.map?.((singleRoute, index) => {
          const selected = isInRoute(location?.pathname, singleRoute?.route);
          return (
            <DrawerSingleItemContainer
              $selected={selected}
              key={index}
              to={singleRoute?.route}
              onClick={props?.onClose}
            >
              {singleRoute?.hoverIcon && selected ? (
                <DrawerSingleItemIcon>
                  {singleRoute?.hoverIcon}
                </DrawerSingleItemIcon>
              ) : (
                <DrawerSingleItemIcon>{singleRoute?.icon}</DrawerSingleItemIcon>
              )}
              <DrawerSingleItemTitle>
                {t(singleRoute?.title)}
              </DrawerSingleItemTitle>
            </DrawerSingleItemContainer>
          );
        })}
      </DrawerItemsContainer>
    </HeaderNewDrawerContainer>
  );
};

HeaderNewDrawer.propTypes = {
  children: PropTypes.node,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
};

export default HeaderNewDrawer;
