import Banner from "components/Banner/Banner";
import TwoParagraphComponent from "components/DataComponents/TwoParagraphComponent/TwoParagraphComponent";
import PageLayout from "layouts/PageLayout";
import React from "react";
import { Trans } from "react-i18next";
//import LeftBuilding from "assets/images/jpg/left-building.jpg";
import ComponentBg from "assets/images/png/component-background.png";
/* import { ReactComponent as Battery } from "assets/images/svg/battery.svg";
import { ReactComponent as HotWater } from "assets/images/svg/hot-water.svg";
import { ReactComponent as EMobility } from "assets/images/svg/e-mobility.svg";
import { ReactComponent as ZEV } from "assets/images/svg/zev.svg";*/
import BannerBg1 from "assets/images/banner/bg01.jpg";
import BannerBg2 from "assets/images/banner/bg02.jpg";
import BannerBg3 from "assets/images/banner/bg03.jpg";
import BannerBg4 from "assets/images/banner/bg04.jpg";
import FourParagraphsComponent from "components/DataComponents/FourParagraphsComponent/FourParagraphsComponent";
import HouseImage from "components/HouseImage/HouseImage";
/* import { primaryThemeColors } from "themes/primaryTheme/primaryThemeColors";
import IconTitle from "components/Typography/IconTitle/IconTitle"; */
import ReferenceImage1 from "assets/images/jpg/reference1.jpg";
import ReferenceImage2 from "assets/images/jpg/reference2.jpg";
import ReferenceImage3 from "assets/images/jpg/reference3.jpg";
import ReferenceImage4 from "assets/images/jpg/reference4.jpg";
import ReferenceImage5 from "assets/images/jpg/reference5.jpg";
import ReferenceImage6 from "assets/images/jpg/reference6.jpg";
import CustomersBg from "assets/images/jpg/customersBg.jpg";
import {
  HomePageSlideshow,
  HouseComponent,
  List,
  ListItem,
  PartnerImage,
} from "./HomePage.styled";
import WhiteColor from "components/Typography/HOC/WhiteColor";
// import { ReactComponent as Empa } from "assets/images/costumers/empa.svg";
// import { ReactComponent as Erne } from "assets/images/costumers/erne.svg";
// import { ReactComponent as Jego } from "assets/images/costumers/jego.svg";
// import { ReactComponent as Pensionskasse } from "assets/images/costumers/pensionskasse.svg";
// import { ReactComponent as WWZ } from "assets/images/costumers/wwz.svg";
import krannich from "assets/images/costumers/krannich.jpg";
import huawei from "assets/images/costumers/huawei.jpg";
import propax from "assets/images/costumers/propax.jpg";
import longiSolar from "assets/images/costumers/longisolar.jpg";
// import swissolar from "assets/images/costumers/swissolar.jpg";
import topbaumaterial from "assets/images/costumers/topbaumaterial.jpg";
import k2Systems from "assets/images/costumers/k2systems.jpg";
import AutoSlideshow from "components/Slideshow/AutoSlideshow/AutoSlideshow";
import { PAGES } from "constants/pages";
import LeftHouseImage from "components/LeftHouseImage/LeftHouseImage";

const bannerSlideshowElements = [
  {
    bgImage: BannerBg1,
    text: <Trans i18nKey="homePage.banner.items.text1" />,
    buttonText: <Trans i18nKey="homePage.banner.items.button1" />,
    buttonLink: PAGES.PHOTOVOLTAIC.route,
    index: 0,
  },
  {
    bgImage: BannerBg2,
    text: <Trans i18nKey="homePage.banner.items.text2" />,
    buttonText: <Trans i18nKey="homePage.banner.items.button2" />,
    index: 1,
  },
  {
    bgImage: BannerBg3,
    text: <Trans i18nKey="homePage.banner.items.text3" />,
    buttonText: <Trans i18nKey="homePage.banner.items.button3" />,
    index: 2,
  },
  {
    bgImage: BannerBg4,
    text: <Trans i18nKey="homePage.banner.items.text4" />,
    buttonText: <Trans i18nKey="homePage.banner.items.button4" />,
    index: 3,
  },
];

const referenceSlideshowElements = [
  {
    bgImage: ReferenceImage1,
    index: 0,
  },
  {
    bgImage: ReferenceImage2,
    index: 1,
  },
  {
    bgImage: ReferenceImage3,
    index: 2,
  },
  {
    bgImage: ReferenceImage4,
    index: 3,
  },
  {
    bgImage: ReferenceImage5,
    index: 4,
  },
  {
    bgImage: ReferenceImage6,
    index: 5,
  },
];

const partnersSlideshowElements = [
  {
    icon: <PartnerImage src={krannich} />,
    href: "https://krannich-solar.com/ch-de/",
  },
  {
    icon: <PartnerImage src={huawei} />,
    href: "https://huawei.com/ch-en/",
  },
  {
    icon: <PartnerImage src={propax} />,
    href: "https://propax.ch/",
  },
  {
    icon: <PartnerImage src={longiSolar} />,
    href: "https://www.longi.com",
  },
  // {
  //   icon: <PartnerImage src={swissolar} />,
  //   href: "https://swissolar.ch",
  // },
  {
    icon: <PartnerImage src={topbaumaterial} />,
    href: "https://topbaumaterial.com",
  },
  {
    icon: <PartnerImage src={k2Systems} />,
    href: "https://k2-systems.com",
  },
];

const HomePage = () => {
  return (
    <PageLayout>
      <Banner slideshowElements={bannerSlideshowElements} isHomePage={true} />
      <TwoParagraphComponent
        bgColor={"white"}
        componentAddon={<LeftHouseImage />}
        rightParagraphTitle={<Trans i18nKey="homePage.paragraph1.title" />}
        rightParagraphText={
          <Trans
            i18nKey="homePage.paragraph1.text"
            components={{
              ul: <List />,
              li: <ListItem />,
            }}
          />
        }
        rightParagraphButtonText={
          <Trans i18nKey="homePage.paragraph1.buttonText" />
        }
        rightParagraphButtonLink={PAGES.TEAM.route}
        //bgImage={LeftBuilding}
      />
      <FourParagraphsComponent
        buttonText={<Trans i18nKey="homePage.paragraph2.buttonText" />}
        buttonLink={PAGES.SERVICES.route}
        headerParagraphText={<Trans i18nKey="homePage.paragraph2.text" />}
        headerParagraphTitle={<Trans i18nKey="homePage.paragraph2.title" />}
        paragraphs={[
          {
            title: (
              <Trans i18nKey="homePage.paragraph2.littleParagraphs.paragraph1.title" />
            ),
            text: (
              <Trans i18nKey="homePage.paragraph2.littleParagraphs.paragraph1.text" />
            ),
          },
          {
            title: (
              <Trans i18nKey="homePage.paragraph2.littleParagraphs.paragraph2.title" />
            ),
            text: (
              <Trans i18nKey="homePage.paragraph2.littleParagraphs.paragraph2.text" />
            ),
          },
          /*  {
            title: (
              <Trans i18nKey="homePage.paragraph2.littleParagraphs.paragraph3.title" />
            ),
            text: (
              <Trans i18nKey="homePage.paragraph2.littleParagraphs.paragraph3.text" />
            ),
          }, */
          {
            title: (
              <Trans i18nKey="homePage.paragraph2.littleParagraphs.paragraph4.title" />
            ),
            text: (
              <Trans i18nKey="homePage.paragraph2.littleParagraphs.paragraph4.text" />
            ),
          },
        ]}
      />
      <HouseComponent
        light
        leftParagraphTitle={<Trans i18nKey="homePage.paragraph3.title" />}
        leftParagraphText={<Trans i18nKey="homePage.paragraph3.text" />}
        leftParagraphButtonText={
          <Trans i18nKey="homePage.paragraph3.buttonText" />
        }
        componentAddon={<HouseImage />}
        bgImage={ComponentBg}
      />
      {/* <FourParagraphsComponent
        leftAlign
        biggerTitle
        alignContainers
        wrap
        bgColor={primaryThemeColors.componentBackgroundColor1}
        buttonText={<Trans i18nKey="homePage.paragraph4.buttonText" />}
        headerParagraphText={<Trans i18nKey="homePage.paragraph4.text" />}
        headerParagraphTitle={<Trans i18nKey="homePage.paragraph4.title" />}
        paragraphs={[
          {
            title: <Battery />,
            text: (
              <IconTitle
                text={<Trans i18nKey="homePage.paragraph4.iconTitle1" />}
              />
            ),
          },
          {
            title: <HotWater />,
            text: (
              <IconTitle
                text={<Trans i18nKey="homePage.paragraph4.iconTitle2" />}
              />
            ),
          },
          {
            title: <EMobility />,
            text: (
              <IconTitle
                text={<Trans i18nKey="homePage.paragraph4.iconTitle3" />}
              />
            ),
          },
          {
            title: <ZEV />,
            text: (
              <IconTitle
                text={<Trans i18nKey="homePage.paragraph4.iconTitle4" />}
              />
            ),
          },
        ]}
      /> */}
      <FourParagraphsComponent
        headerParagraphTitle={<Trans i18nKey="homePage.paragraph5.title" />}
        buttonText={<Trans i18nKey="homePage.paragraph5.buttonText" />}
        componentAddon={
          <HomePageSlideshow slideshowElements={referenceSlideshowElements} />
        }
        buttonLink={PAGES.REFERENZEN.route}
      />
      <FourParagraphsComponent
        headerParagraphTitle={
          <WhiteColor>
            <Trans i18nKey="homePage.paragraph6.title" />
          </WhiteColor>
        }
        headerParagraphText={
          <WhiteColor>
            <Trans i18nKey="homePage.paragraph6.text" />
          </WhiteColor>
        }
        componentAddon={
          <AutoSlideshow slideshowElements={partnersSlideshowElements} />
        }
        bgImage={CustomersBg}
      />
      <FourParagraphsComponent
        buttonLink={PAGES.CONTACT.route}
        headerParagraphTitle={<Trans i18nKey="homePage.paragraph7.title" />}
        headerParagraphText={<Trans i18nKey="homePage.paragraph7.text" />}
        buttonText={<Trans i18nKey="homePage.paragraph7.buttonText" />}
      />
    </PageLayout>
  );
};

export default HomePage;
