import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  AllBannersContainer,
  BannerBottomNavigationContainer,
  BannerBottomNavigationIcon,
  BannerBottomNavigationItemContainer,
  BannerBottomNavigationTitle,
  BannerContainer,
  BannerContentContainer,
  BannerTitle,
  SlideshowChooserContainer,
  SlideshowDot,
} from "./Banner.styled";
import Arrow from "components/Arrow/Arrow";
import { bannerNavigationConstants } from "constants/navigationConstants";
import { useTranslation } from "react-i18next";
import CTAButton from "components/Button/CTAButton/CTAButton";

const Banner = (props) => {
  const { t } = useTranslation();
  const [currentSlideshowElement, setCurrentSlideshowElement] = useState(
    props.slideshowElements?.[0]
  );
  const [display, setDisplay] = useState("none");
  const timeoutRef = useRef(null);

  const changeSlideshowElement = (index) => {
    clearTimeout(timeoutRef?.current);
    setDisplay("flex");
    timeoutRef.current = setTimeout(() => {
      setDisplay("none");
    }, 1500);
    let newIndex = index;
    if (index < 0) newIndex = props?.slideshowElements?.length - 1;
    if (index >= props.slideshowElements?.length) newIndex = 0;
    setTimeout(
      () => setCurrentSlideshowElement(props?.slideshowElements?.[newIndex]),
      0
    );
  };

  useEffect(() => {
    let timeout;
    if (props?.slideshowElements?.length > 1) {
      timeout = setTimeout(() => {
        changeSlideshowElement(currentSlideshowElement?.index + 1);
      }, 6000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [props?.slideshowElements, currentSlideshowElement]);

  const isSlideshow = useMemo(
    () => props?.slideshowElements?.length > 1,
    [props?.slideshowElements]
  );
  return (
    <AllBannersContainer $smaller={!props?.isHomePage}>
      {props?.slideshowElements?.map?.((singleElement, index) => (
        <BannerContainer
          key={index}
          $bgImage={singleElement?.bgImage}
          style={{
            display:
              currentSlideshowElement?.index === index ? "flex" : display,
          }}
          $isShown={currentSlideshowElement?.index === index}
          $centerAlign={!isSlideshow}
        >
          {isSlideshow && (
            <Arrow
              left
              onClick={() =>
                changeSlideshowElement(currentSlideshowElement?.index - 1)
              }
            />
          )}
          <BannerContentContainer>
            <BannerTitle>{singleElement?.text}</BannerTitle>
            {singleElement?.buttonText && (
              <CTAButton to={singleElement?.buttonLink}>
                {singleElement?.buttonText}
              </CTAButton>
            )}
          </BannerContentContainer>
          {isSlideshow && (
            <Arrow
              onClick={() =>
                changeSlideshowElement(currentSlideshowElement?.index + 1)
              }
            />
          )}
        </BannerContainer>
      ))}
      {isSlideshow && (
        <SlideshowChooserContainer>
          {props?.slideshowElements?.map?.((singleElement, index) => (
            <SlideshowDot
              key={index}
              onClick={() => changeSlideshowElement(index)}
              $current={currentSlideshowElement?.index === index}
            />
          ))}
        </SlideshowChooserContainer>
      )}
      {props?.isHomePage && (
        <BannerBottomNavigationContainer>
          {bannerNavigationConstants?.map?.((singleRoute, index) => (
            <BannerBottomNavigationItemContainer
              key={index}
              to={singleRoute?.route}
            >
              <BannerBottomNavigationTitle>
                {t(singleRoute?.title)}
              </BannerBottomNavigationTitle>
              <BannerBottomNavigationIcon>
                {singleRoute?.icon}
              </BannerBottomNavigationIcon>
            </BannerBottomNavigationItemContainer>
          ))}
        </BannerBottomNavigationContainer>
      )}
    </AllBannersContainer>
  );
};

Banner.propTypes = {
  children: PropTypes.node,
  slideshowElements: PropTypes.array,
  isHomePage: PropTypes.bool,
};

export default Banner;
