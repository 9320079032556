import { Box } from "@mui/material";
import styled from "styled-components";

export const PageTitleContainer = styled(Box)`
  color: ${(props) => props?.theme?.colors?.blackTextColor};
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.1;
  font-size: 36px;
  @media (max-width: 959px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;
