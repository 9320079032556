import { Box } from "@mui/material";
import styled, { css } from "styled-components";
 
export const HeaderCustomDrawerContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 40px;
  cursor: pointer;
`;
export const HeaderDrawerText = styled(Box)`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colors?.whiteColor};
  text-transform: uppercase;
  letter-spacing: 1px;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const HeaderHamburgerSingleLine = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props?.theme?.colors?.whiteColor};
`;
export const HeaderHamburgerLines = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 5px;
  width: 40px;
  height: 40px;
  padding: 10px;
  padding-right: 11px;
  padding-left: 11px;
  border-radius: 100%;
  background-color: ${(props) => props?.theme?.colors?.greenColor};
  z-index: 100;
  & * {
    transition-duration: 0.3s;
    transition-delay: 0.1s;
  }
  /* & ${HeaderHamburgerSingleLine}:nth-child(1) {
    position: absolute;
    top: 0;
  }
  & ${HeaderHamburgerSingleLine}:nth-child(2) {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
  & ${HeaderHamburgerSingleLine}:nth-child(3) {
    position: absolute;
    bottom: 0;
  } */
  ${(props) =>
    props?.$isOpened &&
    css`
      & ${HeaderHamburgerSingleLine}:nth-child(1) {
        transform: rotate(45deg);
        top: 10px;
      }
      & ${HeaderHamburgerSingleLine}:nth-child(2) {
        width: 0;
      }
      & ${HeaderHamburgerSingleLine}:nth-child(3) {
        transform: rotate(-45deg);
        bottom: 10px;
      }
    `}
`;
export const ReactDrawerContent = styled(Box)`
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-color: ${(props) => props?.theme?.colors?.yellowColor};
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
  transition-duration: 0.3s;
  ${(props) =>
    props?.$isOpened &&
    css`
      z-index: 100;
      opacity: 1;
      & > ${ReactDrawerContent2} {
        background-color: ${(props) => props?.theme?.colors?.yellowColor};
      }
    `}
`;
export const ReactDrawerContent2 = styled(Box)`
  width: 100vw;
  max-width: 100%;
  transition-duration: 0.3s;
  height: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 0;
`;