import { Box } from "@mui/material";
import styled from "styled-components";

export const SocialHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-right: 20px;
  align-items: center;
  height: 34px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 102;
  //z-index: 4;
  @media (max-width: 600px) {
    gap: 12px;
  }
`;
export const SocialButtonsContainer = styled(Box)`
  display: flex;
  gap: 9.5px;
  align-items: center;
`;
