import React from "react";
import PropTypes from "prop-types";
import PageLayout from "layouts/PageLayout";
import Banner from "components/Banner/Banner";
import FourParagraphsComponent from "components/DataComponents/FourParagraphsComponent/FourParagraphsComponent";
import {
  ContactDetails,
  LogoImageContainer,
} from "./ContactPage.styled";
import Map from "components/Map/Map";
// import MapImage from "assets/images/jpg/map.jpg";
import { Trans } from "react-i18next";
import BannerBg from "assets/images/jpg/bannerBg1.jpg";
import Logo from "components/Logo/Logo";

const ContactPage = () => {
  return (
    <PageLayout>
      <Banner
        slideshowElements={[
          {
            bgImage: BannerBg,
            text: <Trans i18nKey="contactPage.banner.title" />,
            index: 0,
          },
        ]}
      />
      <FourParagraphsComponent
        headerParagraphTitle={<Trans i18nKey="contactPage.paragraph1.title" />}
        componentAddon={<ContactDetails />}
      />
      <LogoImageContainer>
        <Logo/>
      </LogoImageContainer>
      <Map
        href="https://www.google.com/maps?ll=47.21144,8.559939&z=19&t=m&hl=de&gl=CH&mapclient=embed&cid=15629551403709213910"
      />
    </PageLayout>
  );
};

ContactPage.propTypes = {
  children: PropTypes.node,
};

export default ContactPage;
