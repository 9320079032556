import { Box } from "@mui/material";
import Link from "components/Link/Link";
import DrawerComponent from "components/MUI/DrawerComponent";
import styled, { css } from "styled-components";
import { hexToRGB } from "util/helpers/colorHelpers";

export const HeaderNewDrawerContainer = styled(DrawerComponent)`
  & > .MuiDrawer-paper {
    background-color: ${(props) => props?.theme?.colors?.greenColor} !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    padding: 20px;
  }
`;
export const DrawerItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
export const DrawerSingleItemTitle = styled(Box)`
  color: ${(props) => props?.theme?.colors?.whiteColor};
  font-weight: 500;
`;

export const DrawerSingleItemIcon = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    height: 20px;
  }
`;

export const DrawerSingleItemContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  padding: 12px 16px;
  & ${DrawerSingleItemIcon} {
    & path {
      fill: ${(props) => props?.theme?.colors?.whiteColor};
    }
  }
  ${(props) =>
    props?.$selected
      ? css`
          background: ${(props) =>
            `linear-gradient(to right, ${hexToRGB(
              props?.theme?.colors?.yellowColor,
              1
            )}, ${hexToRGB(props?.theme?.colors?.yellowColor, 0.85)})`};

          box-shadow: 3px 3px 15px 5px
            ${(props) => hexToRGB(props?.theme?.colors?.blackColor, 0.3)};
          & ${DrawerSingleItemTitle} {
            color: ${(props) => props?.theme?.colors?.greenColor};
          }
          & ${DrawerSingleItemIcon} {
            & path {
              fill: ${(props) => props?.theme?.colors?.greenColor};
            }
          }
        `
      : css`
          &:hover {
            & ${DrawerSingleItemTitle} {
              color: ${(props) => props?.theme?.colors?.greenColor};
            }
            & ${DrawerSingleItemIcon} {
              & path {
                fill: ${(props) => props?.theme?.colors?.greenColor};
              }
            }
            box-shadow: 3px 3px 15px 5px
              ${(props) => hexToRGB(props?.theme?.colors?.blackColor, 0.3)};
            background: ${(props) =>
              `linear-gradient(to right, ${hexToRGB(
                props?.theme?.colors?.yellowColor,
                1.0
              )}, ${hexToRGB(props?.theme?.colors?.yellowColor, 0.9)})`};
          }
        `}
`;
