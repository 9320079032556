import React from "react";
import PropTypes from "prop-types";

const NormalWeightText = (props) => {
  return (
    <span style={{ fontWeight: "normal" }} {...props}>
      {props?.children}
    </span>
  );
};

NormalWeightText.propTypes = {
  children: PropTypes.node,
};

export default NormalWeightText;
