import { Box, IconButton } from "@mui/material";
import Link from "components/Link/Link";
import { LogoContainer } from "components/Logo/Logo.styled";
import styled from "styled-components";
import { hexToRGB } from "util/helpers/colorHelpers";

export const HeaderIconContainer = styled(IconButton)`
  cursor: pointer;
  min-width: 0;
  border-radius: 100%;
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.primaryLighter};
  }
  & svg {
    fill: ${(props) => props?.theme?.colors?.textColor};
  }
`;
export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 55px;
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 101;
  //transition-duration: 0.3s;
  top: 34px;
  background: #ffffff;
  @media (max-width: 600px) {
    padding: 0 30px;
  }
`;
export const HeaderSideContainer = styled(Box)`
  display: flex;
  gap: 24px;
  height: 100%;
  align-items: center;
  z-index: 101;
  @media (max-width: 600px) {
    gap: 2px !important;
  }
`;
export const LogoLink = styled(Link)`
  & svg {
    height: 60px;
    width: 320px;
    & > ${LogoContainer} {
      height: 60px;
      width: 320px;
    }
    @media (max-width: 600px) {
      max-width: 60vw;
      height: auto;
      & > ${LogoContainer} {
        max-width: 60vw;
        height: auto;
      }
    }
  }
`;
export const LogoText = styled(Box)`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => hexToRGB(props?.theme?.colors?.whiteColor, 1)};
  background-color: ${(props) => hexToRGB(props?.theme?.colors?.greenColor, 1)};
  /* letter-spacing: 1.52px; */
  letter-spacing: 2.4px;
  //letter-spacing: 3.2px;
  padding: 1px 4px;
  border-radius: 1px;
  @media (max-width: 600px) {
    width: 58.5vw;
    letter-spacing: normal;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    & span {
      flex: 1;
    }
  }
`;
