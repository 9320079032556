import React, { forwardRef } from "react";
import PropTypes from "prop-types";
//import { LogoContainer } from "./Logo.styled";
//import LogoImage from "assets/images/logo/logoTransparent.png";
//import LogoImage from "assets/images/logo/MegawattSolar_Logo.png"
import {ReactComponent as LogoImage} from "assets/images/logo/MegawattSolar_04.svg"
import { LogoContainer } from "./Logo.styled";

const Logo = forwardRef((props, ref) => {
  return <LogoContainer {...props} > <LogoImage  ref={ref} /> </LogoContainer>;
});

Logo.displayName = "Logo";

Logo.propTypes = {
  children: PropTypes.node,
};

export default Logo;
