import Link from "components/Link/Link";
import Logo from "components/Logo/Logo";
import styled from "styled-components";

export const MapContainer = styled(Link)`
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
  min-height: 600px;
  padding: 70px 30px;
  position: relative;
  @media (max-width: 600px) {
    padding: 40px 30px;
  }
  & > div {
    min-height: 600px;
  }
`;
export const MapImage = styled.img`
  width: 100%;
  height: auto;
`;
export const LogoImage = styled(Logo)`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  @media (max-width: 600px) {
    display: none;
  }
`
