import React from "react";
import PropTypes from "prop-types";
import { LeftHouseImageContainer } from "./LeftHouseImage.styled";

const LeftHouseImage = (props) => {
  return <LeftHouseImageContainer {...props} />;
};

LeftHouseImage.propTypes = {
  children: PropTypes.node,
};

export default LeftHouseImage;
