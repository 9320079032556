import { Card, Box } from "@mui/material";
import MailToIcon from "components/Icons/MailToIcon";
import Link from "components/Link/Link";
import styled, { css } from "styled-components";

export const TeamContent = styled(Card)`
  border-radius: 0px;
  box-shadow: none;
  margin-bottom: 20px;
  padding-left: 30px;
  flex: 0 0 33.333333%;
  transition: none;
  opacity: 0;
  position: relative;
  bottom: -10px;
  transition: opacity 0.5s ${(props) => (props.index % 3) * 0.2 + 0.1}s,
    bottom 0s 0.5s;
  ${(props) =>
    props?.$shouldShowComponent &&
    css`
      bottom: 0;
      opacity: 1;
    `}
`;
export const TeamContentImageWrapper = styled(Box)`
  flex: 1;
  margin-bottom: 20px;
`;

export const TeamContentText = styled(Box)`
  font-size: 16px;
  color: #002546;
  font-weight: 300;
`;
export const TeamContentInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TeamContentTitle = styled(Box)`
  margin-bottom: 5px;
  width: 100%;
  font-size: 16px;
  color: #002546;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  overflow-x: hidden;
`;
export const Image = styled.img`
  width: 100%;
  height: auto;
`;
export const TeamContentContainer = styled(Box)`
  padding-top: 70px;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const TeamContentMailWrapper = styled(Link)``;

export const SingleParagraphTitle = styled(Box)`
  font-weight: 700;
  letter-spacing: 1px;
  color: ${(props) => props?.theme?.colors?.fontColor};
  line-height: 1.1;
  font-size: 22px;
  text-align: center;
  @media (max-width: 959px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const SingleParagraphText = styled(Box)`
  font-size: 12pt;
  color: ${(props) => props?.theme?.colors?.fontColor};
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ImageMail = styled(MailToIcon)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.grayColor};
  }
  &:hover {
    & path {
      stroke: ${(props) => props?.theme?.colors?.yellowColor};
    }
  }
`;
export const TeamContentTextWrapper = styled(Box)``;
