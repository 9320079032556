import React from "react";
import PropTypes from "prop-types";
import {
  SocialButtonsContainer,
  SocialHeaderContainer,
} from "./SocialHeader.styled";
import FacebookButtonLink from "components/SocialLinks/FacebookButtonLink/FacebookButtonLink";
import LinkedinButtonLink from "components/SocialLinks/LinkedinButtonLink/LinkedinButtonLink";
import InstagramButtonLink from "components/SocialLinks/InstagramButtonLink/InstagramButtonLink";
import SocialLink from "./SocialLink/SocialLink";
import { MAIL, PHONE_NUMBER } from "constants/linkConstants";
import { linkVariants } from "constants/linkVariants";

const SocialHeader = () => {
  return (
    <SocialHeaderContainer>
      <SocialButtonsContainer>
        <FacebookButtonLink />
        <LinkedinButtonLink />
        <InstagramButtonLink />
      </SocialButtonsContainer>
      <SocialLink to={`mailto:${MAIL}`} variant={linkVariants.grayInitial}>
        {MAIL}
      </SocialLink>
      <SocialLink to={`tel:${PHONE_NUMBER}`} variant={linkVariants.grayInitial}>
        {PHONE_NUMBER}
      </SocialLink>
    </SocialHeaderContainer>
  );
};

SocialHeader.propTypes = {
  children: PropTypes.node,
};

export default SocialHeader;
