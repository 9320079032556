import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  HeaderParagraphContainer,
  ParagraphsContentContainer,
  SingleParagraphContainer,
  SingleParagraphText,
  SingleParagraphTitle,
  TwoParagraphComponentContainer,
} from "./TwoParagraphComponent.styled";
import CTAButton from "components/Button/CTAButton/CTAButton";
import useDevice from "hooks/useDevice";

const TwoParagraphComponent = (props) => {
  const containerRef = useRef(null);
  const [shouldShowText, setShouldShowText] = useState(false);
  const { isMobile } = useDevice();

  const scrollListener = (event) => {
    let scrollTop = event?.target?.scrollingElement?.scrollTop;
    let clientHeight = event?.target?.scrollingElement?.clientHeight;
    let offsetTop = containerRef?.current?.offsetTop;
    let scrollY = window.scrollY + window.innerHeight;
    let shouldShowText;
    if (event) shouldShowText = scrollTop + clientHeight > offsetTop;
    else shouldShowText = scrollY > offsetTop;
    setShouldShowText(shouldShowText);
  };

  useEffect(() => {
    scrollListener();
  }, []);

  useEffect(() => {
    if (!shouldShowText) window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, [containerRef.current, shouldShowText]);

  return (
    <TwoParagraphComponentContainer
      $bgImage={props?.bgImage}
      $bgColor={props?.bgColor}
      ref={containerRef}
      $shouldShowText={shouldShowText}
      $light={props?.light}
      className={props?.className}
    >
      {(props?.headerParagraphTitle || props?.headerParagraphText) && (
        <HeaderParagraphContainer>
          <SingleParagraphTitle>
            {props?.headerParagraphTitle}
          </SingleParagraphTitle>
          <SingleParagraphText>
            {props?.headerParagraphText}
          </SingleParagraphText>
          {props?.headerParagraphButtonText && (
            <CTAButton to={props?.headerButtonLink} onClick={props?.headerParagraphButtonClick}>
              {props?.headerParagraphButtonText}
            </CTAButton>
          )}
        </HeaderParagraphContainer>
      )}
      <ParagraphsContentContainer $shouldShowText={shouldShowText}>
        {!(
          isMobile && !(props?.leftParagraphTitle || props?.leftParagraphText)
        ) && (
          <SingleParagraphContainer>
            <SingleParagraphTitle>
              {props?.leftParagraphTitle}
            </SingleParagraphTitle>
            <SingleParagraphText>
              {props?.leftParagraphText}
            </SingleParagraphText>
            {props?.leftParagraphButtonText && (
              <CTAButton onClick={props?.leftParagraphButtonClick}>
                {props?.leftParagraphButtonText}
              </CTAButton>
            )}
          </SingleParagraphContainer>
        )}
        <SingleParagraphContainer>
          <SingleParagraphTitle>
            {props?.rightParagraphTitle}
          </SingleParagraphTitle>
          <SingleParagraphText>{props?.rightParagraphText}</SingleParagraphText>
          {props?.rightParagraphButtonText && (
            <CTAButton to={props?.rightParagraphButtonLink} onClick={props?.rightParagraphButtonClick}>
              {props?.rightParagraphButtonText}
            </CTAButton>
          )}
        </SingleParagraphContainer>
      </ParagraphsContentContainer>
      {props?.componentAddon}
    </TwoParagraphComponentContainer>
  );
};

TwoParagraphComponent.propTypes = {
  bgImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  leftParagraphTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  leftParagraphText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  leftParagraphButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  leftParagraphButtonClick: PropTypes.func,
  rightParagraphTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  rightParagraphText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  rightParagraphButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  rightParagraphButtonClick: PropTypes.func,
  headerParagraphTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  headerParagraphText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  headerParagraphButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  headerParagraphButtonClick: PropTypes.func,
  light: PropTypes.bool,
  componentAddon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  className: PropTypes.string,
  headerButtonLink: PropTypes.string,
  rightParagraphButtonLink: PropTypes.string,
  bgColor: PropTypes.string
};

export default TwoParagraphComponent;
