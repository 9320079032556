import React from "react";
import { ReactComponent as InstagramIconSvg } from "assets/images/svg/instagram.svg";

const InstagramIcon = (props) => {
  return <InstagramIconSvg {...props} />;
};

InstagramIcon.propTypes = {};

export default InstagramIcon;
