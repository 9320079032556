import PageLayout from "layouts/PageLayout";
import styled from "styled-components";

export const ImpressumPageLayout = styled(PageLayout)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-direction: column;
  padding-bottom: 40px;
`;
