/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ImageInfoDialogRow } from './ImageInfoDialog.styled';
import { useTranslation } from 'react-i18next';

const Transition = forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const ImageInfoDialog = ({ handleClose, open, details }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      autoscrollbodycontent="true"
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
    >
      <DialogTitle sx={{ m: 0, color: '#002546' }}>
        {details?.installationLocation}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <ImageInfoDialogRow>{`${t('common.installationLocation')}: ${
          details?.installationLocation
        }`}</ImageInfoDialogRow>
        <ImageInfoDialogRow>{`${t('common.installedCapacity')}: ${
          details?.installedCapacity
        }`}</ImageInfoDialogRow>
        <ImageInfoDialogRow>{`${t('common.installedModules')}: ${
          details?.installedModules
        }`}</ImageInfoDialogRow>
        <ImageInfoDialogRow>{`${t('common.installation')}: ${
          details?.installation
        }`}</ImageInfoDialogRow>
      </DialogContent>
    </Dialog>
  );
};

export default ImageInfoDialog;