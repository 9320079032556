import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  COMPANY_LAT,
  COMPANY_LNG,
  COMPANY_NAME,
} from "constants/linkConstants";
import selectedTheme from "themes";

const MapComponent = (props) => {
  const ref = useRef();

  useEffect(() => {
    let map = new window.google.maps.Map(ref.current, {
      center: props?.center,
      zoom: props?.zoom,
      styles: [
        { elementType: "geometry", stylers: [{ color: selectedTheme.colors.blackTextColor }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: selectedTheme.colors.yellowColor }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: selectedTheme.colors.yellowColor }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: selectedTheme.colors.yellowColor }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: selectedTheme.colors.waterColor }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ],
    });
    new window.google.maps.Marker({
      map,
      position: { lat: COMPANY_LAT, lng: COMPANY_LNG },
      title: COMPANY_NAME,
    });
  });

  return <div ref={ref} id="map" />;
};

MapComponent.propTypes = {
  center: PropTypes.any,
  zoom: PropTypes.any,
};

export default MapComponent;
