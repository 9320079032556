import React from "react";
import PropTypes from "prop-types";
import { HouseImageContainer } from "./HouseImage.styled";

const HouseImage = (props) => {
  return <HouseImageContainer {...props} />;
};

HouseImage.propTypes = {
  children: PropTypes.node,
};

export default HouseImage;
