import React from "react";
import PropTypes from "prop-types";
import { ArrowContainer } from "./Arrow.styled";
import ArrowIcon from "components/Icons/ArrowIcon";

const Arrow = (props) => {
  return (
    <ArrowContainer $width={props?.width} $left={props?.left} {...props}>
      <ArrowIcon />
    </ArrowContainer>
  );
};

Arrow.propTypes = {
  width: PropTypes.string,
  left: PropTypes.bool,
};
Arrow.defaultProps = {
  width: "30px",
};

export default Arrow;
