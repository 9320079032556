import React from "react";
import PropTypes from "prop-types";
import { ImpressumComponentContainer } from "./ImpressumComponent.styled";

const ImpressumComponent = (props) => {
  return <ImpressumComponentContainer {...props} />;
};

ImpressumComponent.propTypes = {
  children: PropTypes.node,
};

export default ImpressumComponent;
