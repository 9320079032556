import React from "react";
import { ReactComponent as ArrowIconSvg } from "assets/images/svg/arrow.svg";

const ArrowIcon = (props) => {
  return <ArrowIconSvg {...props} />;
};

ArrowIcon.propTypes = {};

export default ArrowIcon;
