import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import styled from "styled-components";

const PageLayout = (props) => {
  return (
    <PageLayoutContainer className={props?.className}>
      {props?.children}
    </PageLayoutContainer>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PageLayout;

export const PageLayoutContainer = styled(Box)`
  min-height: 80vh;
`;
