import React from "react";
import { ReactComponent as FacebookIconSvg } from "assets/images/svg/facebook.svg";

const FacebookIcon = (props) => {
  return <FacebookIconSvg {...props} />;
};

FacebookIcon.propTypes = {};

export default FacebookIcon;
