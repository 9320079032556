import { Link } from "react-router-dom";
import styled from "styled-components";

export const ButtonLinkContainer = styled(Link)`
  background-color: ${(props) => props?.theme?.colors?.grayColor};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.yellowColor};
  }
`;
