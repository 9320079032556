import ref1 from "assets/images/referenzen/ref01.jpg";
import ref2 from "assets/images/referenzen/ref02.jpg";
import ref3 from "assets/images/referenzen/ref03.jpg";
import ref4 from "assets/images/referenzen/ref04.jpg";
import ref5 from "assets/images/referenzen/ref05.jpg";
import ref6 from "assets/images/referenzen/ref06.jpg";
import ref7 from "assets/images/referenzen/ref07.jpg";
import ref8 from "assets/images/referenzen/ref08.jpg";
import ref9 from "assets/images/referenzen/ref09.jpg";
import ref10 from "assets/images/referenzen/ref10.jpg";
import ref11 from "assets/images/referenzen/ref11.jpg";
import ref12 from "assets/images/referenzen/ref12.jpg";

export const galleryItems = [
    {
      category: "",
      image: ref1,
      installationLocation: "6353 Weggis, Luzern",
      installedCapacity: "13.12 kWp",
      installedModules: "31x Trina TSM 425 Watt",
      installation: "28.09.2023",
    },
    {
      category: "",
      image: ref2,
      installationLocation: "5036 Oberentfelden, Aargau",
      installedCapacity: "8.4 kWp",
      installedModules: "20x Trina TSM-420W",
      installation: "Dezember 2023",
    },
    {
      category: "",
      image: ref3,
      installationLocation: "8463 Benken, Zürich",
      installedCapacity: "25.6 kWp",
      installedModules: "59x Longi Solar 435W Fullblack",
      installation: "April 2024",
    },
    {
      category: "",
      image: ref4,
      installationLocation: "4853 Murgenthal, Aargau",
      installedCapacity: "29.58 kWp",
      installedModules: "68x Longi Solar 435W Fullblack",
      installation: "Februar 2024",
    },
    {
      category: "",
      image: ref5,
      installationLocation: "4853 Murgenthal, Aargau",
      installedCapacity: "30 kWp",
      installedModules: "69x Longi Solar 435W Fullblack",
      installation: "Februar 2024",
    },
    {
      category: "",
      image: ref6,
      installationLocation: "8118 Pfaffhausen, Zürich",
      installedCapacity: "24.5 kWp",
      installedModules: "62x Meyerburger 395W Fullblack",
      installation: "Februar 2024",
    },
    {
      category: "",
      image: ref7,
      installationLocation: "9475 Sevelen, St. Gallen",
      installedCapacity: "168 kWp",
      installedModules: "390x DAS Solar 435W",
      installation: "In Montage",
    },
    {
      category: "",
      image: ref8,
      installationLocation: "4515 Lommiswil, Solothurn",
      installedCapacity: "15.2 kWp",
      installedModules: "35x Longi Solar 435W Fullblack",
      installation: "März 2024",
    },
    {
      category: "",
      image: ref9,
      installationLocation: "5036 Oberentfelden, Aargau",
      installedCapacity: "26.97 kWp",
      installedModules: "62x Longi Solar 435W Fullblack",
      installation: "Juni 2024",
    },
    {
      category: "",
      image: ref10,
      installationLocation: "8054 Oberglatt, Zürich",
      installedCapacity: "10 kWp",
      installedModules: "24x Trina TSM 420W",
      installation: "Februar 2024",
    },
    {
      category: "",
      image: ref11,
      installationLocation: "6045 Meggen, Luzern",
      installedCapacity: "11.3 kWp",
      installedModules: "26x Trina 435W",
      installation: "Februar 2024",
    },
    {
      category: "",
      image: ref12,
      installationLocation: "4535 Hubersdorf, Solothurn",
      installedCapacity: "16.15 kWp",
      installedModules: "38x Trina TSM-425W",
      installation: "September 2023",
    },
  ];