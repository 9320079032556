import React from "react";
import PropTypes from "prop-types";
import {
  FooterItem,
  FooterItemsContainer,
  FooterSection,
  FooterTitle,
} from "../Footer.styled";
import { useTranslation } from "react-i18next";
import {
  COMPANY_ADDRESS,
  COMPANY_CITY,
  COMPANY_NAME,
  MAIL,
  PHONE_NUMBER,
  WEBSITE,
} from "constants/linkConstants";
import { linkVariants } from "constants/linkVariants";
import { removeHttp } from "util/routeHelpers";

const ContactSection = (props) => {
  const { t } = useTranslation();
  return (
    <FooterSection className={props?.className}>
      <FooterTitle>{t(props?.title)}</FooterTitle>
      <FooterItemsContainer>
        <FooterItem variant={linkVariants.whiteInitial}>
          {props?.isUpperCase
            ? props?.companyName?.toUpperCase?.()
            : props?.companyName}
        </FooterItem>
        <FooterItem variant={linkVariants.whiteInitial}>
          {props?.companyAddress}
        </FooterItem>
        <FooterItem variant={linkVariants.whiteInitial}>
          {props?.companyCity}
        </FooterItem>
      </FooterItemsContainer>
      <FooterItemsContainer>
        {props?.companyPhone !== null && (
          <FooterItem
            to={`tel:${props?.companyPhone}`}
            variant={linkVariants.yellowInitial}
          >
            {props?.companyPhone}
          </FooterItem>
        )}

        <FooterItem
          to={`mailto:${props?.companyMail}`}
          variant={linkVariants.yellowInitial}
        >
          {props?.companyMail}
        </FooterItem>
        <FooterItem
          to={props?.companyWebsite}
          variant={linkVariants.yellowInitial}
        >
          {removeHttp(props?.companyWebsite)}
        </FooterItem>
      </FooterItemsContainer>
    </FooterSection>
  );
};

ContactSection.propTypes = {
  children: PropTypes.node,
  omitTitle: PropTypes.bool,
  blackText: PropTypes.bool,
  className: PropTypes.string,
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
  companyCity: PropTypes.string,
  companyPhone: PropTypes.string,
  companyMail: PropTypes.string,
  companyWebsite: PropTypes.string,
  title: PropTypes.string,
  isUpperCase: PropTypes.bool,
};

ContactSection.defaultProps = {
  title: "contact.title",
  companyName: COMPANY_NAME,
  companyAddress: COMPANY_ADDRESS,
  companyCity: COMPANY_CITY,
  companyPhone: PHONE_NUMBER,
  companyMail: MAIL,
  companyWebsite: WEBSITE,
  isUpperCase: true
};

export default ContactSection;
