/* eslint-disable react/prop-types */
import React from "react";
import {
  TeamMemberCardContainer,
  TeamMemberImageContainer,
} from "./TeamMemberCard.styled";
import { SingleParagraphText } from "components/DataComponents/TeamComponent/TeamComponent.styled";

const TeamMemberCard = ({ member }) => {
  return (
    <TeamMemberCardContainer>
      <TeamMemberImageContainer />
    
      <SingleParagraphText>{member.name} {member.surname && member.surname}</SingleParagraphText>
      <SingleParagraphText style={{marginTop: 0, marginBottom: 0}}>{member.position && member.position}</SingleParagraphText>
    </TeamMemberCardContainer>
  );
};

export default TeamMemberCard;
