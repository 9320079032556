import Linkedin from "components/Icons/LinkedinIcon";
import styled from "styled-components";

export const LinkedinIcon = styled(Linkedin)`
  width: 15px;
  height: 15px;
  path {
    fill: #fff;
  }
`;
