import React from "react";
import { ReactComponent as MinusIconSvg } from "assets/images/svg/minus.svg";

const MinusIcon = (props) => {
  return <MinusIconSvg {...props} />;
};

MinusIcon.propTypes = {};

export default MinusIcon;
