import React from "react";
import PageLayout from "layouts/PageLayout";
import Banner from "components/Banner/Banner";
import { Trans } from "react-i18next";
import BannerBg from "assets/images/jpg/bannerBg1.jpg";
import FourParagraphsComponent from "components/DataComponents/FourParagraphsComponent/FourParagraphsComponent";
import PhotoVoltaicComponent from "components/DataComponents/PhotoVoltaicComponent/PhotoVoltaicComponent";
import ServicesImage1 from "assets/images/jpg/services1.jpg";
import ServicesImage2 from "assets/images/jpg/services2.jpg";
import ServicesImage3 from "assets/images/jpg/services3.jpg";

const ServicesPage = () => {
  return (
    <PageLayout>
      <Banner
        slideshowElements={[
          {
            bgImage: BannerBg,
            text: <Trans i18nKey="servicesPage.banner.title" />,
            index: 0,
          },
        ]}
      />
      <FourParagraphsComponent
        biggerText
        biggerHeader
        headerParagraphTitle={
          <Trans i18nKey="servicesPage.paragraph1.title" />
        }
        headerParagraphText={
          <Trans i18nKey="servicesPage.paragraph1.text" />
        }
      />
      <PhotoVoltaicComponent
        reverse
        imgSrc={ServicesImage1}
        paragraphTitle={
          <Trans i18nKey="servicesPage.paragraph2.subtitle" />
        }
        paragraphText={<Trans i18nKey="servicesPage.paragraph2.text" />}
        dropDownData={Array(1)
          .fill(null, 0)
          .map((item, index) => ({
            dropdownTitle: (
              <Trans
                i18nKey={`servicesPage.paragraph2.dropDownData.${index}.dropdownTitle`}
              />
            ),
            dropdownText: (
              <Trans
                i18nKey={`servicesPage.paragraph2.dropDownData.${index}.dropdownText`}
              />
            ),
          }))}
      />
      <PhotoVoltaicComponent
        light
        imgSrc={ServicesImage2}
        paragraphTitle={
          <Trans i18nKey="servicesPage.paragraph3.subtitle" />
        }
        paragraphText={<Trans i18nKey="servicesPage.paragraph3.text" />}
        dropDownData={Array(3)
          .fill(null, 0)
          .map((item, index) => ({
            dropdownTitle: (
              <Trans
                i18nKey={`servicesPage.paragraph3.dropDownData.${index}.dropdownTitle`}
              />
            ),
            dropdownText: (
              <Trans
                i18nKey={`servicesPage.paragraph3.dropDownData.${index}.dropdownText`}
              />
            ),
          }))}
      />
      <PhotoVoltaicComponent
        reverse
        imgSrc={ServicesImage3}
        paragraphTitle={
          <Trans i18nKey="servicesPage.paragraph5.subtitle" />
        }
        paragraphText={<Trans i18nKey="servicesPage.paragraph5.text" />}
        dropDownData={Array(2)
          .fill(null, 0)
          .map((item, index) => ({
            dropdownTitle: (
              <Trans
                i18nKey={`servicesPage.paragraph5.dropDownData.${index}.dropdownTitle`}
              />
            ),
            dropdownText: (
              <Trans
                i18nKey={`servicesPage.paragraph5.dropDownData.${index}.dropdownText`}
              />
            ),
          }))}
      />
    </PageLayout>
  );
};
export default ServicesPage;
