import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "@mui/material";

const DrawerComponent = (props) => (
  <Drawer
    sx={{
      minWidth: 250,
      "& .MuiDrawer-paper": {
        minWidth: 250,
      },
    }}
    // anchor={props?.anchor}
    open={props?.isOpened}
    // onClose={props?.onClose}
    {...props}
  >
    {props?.children}
  </Drawer>
);

DrawerComponent.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.any,
  anchor: PropTypes.oneOf(["top", "right", "left", "bottom"]),
  children: PropTypes.node,
};

export default DrawerComponent;
