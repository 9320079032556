import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { MapContainer } from "./Map.styled";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import MapComponent from "./MapComponent";
import { COMPANY_LAT, COMPANY_LNG } from "constants/linkConstants";

const Map = () => {
  const render = useCallback((status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return null;
  }, []);
  const center = useMemo(() => ({ lat: COMPANY_LAT, lng: COMPANY_LNG }), []);
  const zoom = 15;
  return (
    <MapContainer>
      <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render}>
        <MapComponent center={center} zoom={zoom} />
      </Wrapper>
    </MapContainer>
  );
};

Map.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mapSrc: PropTypes.string,
};

export default Map;
