import React from "react";
import PropTypes from "prop-types";
import {
  HeaderContainer,
  HeaderSideContainer,
  LogoLink,
  LogoText,
} from "./Header.styled";
import Logo from "components/Logo/Logo";
import { PAGES } from "constants/pages";
import HeaderCustomDrawer from "./HeaderCustomDrawer/HeaderCustomDrawer";
import { useDispatch, useSelector } from "react-redux";
import { selectIsMenuOpened, setIsMenuOpened } from "features/app/appSlice";
import { useTranslation } from "react-i18next";
import HeaderNewDrawer from "./HeaderNewDrawer/HeaderNewDrawer";
import Link from "components/Link/Link";

const Header = () => {
  const { t } = useTranslation();
  const isMenuOpened = useSelector(selectIsMenuOpened);
  const dispatch = useDispatch();

  const handleToggleDrawer = () => {
    dispatch(setIsMenuOpened());
  };

  return (
    <>
      <HeaderContainer>
        <HeaderSideContainer>
          <HeaderCustomDrawer />
        </HeaderSideContainer>
        <HeaderSideContainer
          style={{
            flexDirection: "column",
            gap: "4px",
            justifyContent: "center",
          }}
          component={Link}
          to={PAGES.BASE.route}
        >
          <LogoLink>
            <Logo />
          </LogoLink>
          <LogoText>
            {t("header.logoText")
              ?.split("")
              ?.map?.((singleChar, index) => (
                <span key={index}>{singleChar}</span>
              ))}
          </LogoText>
        </HeaderSideContainer>
      </HeaderContainer>
      <HeaderNewDrawer
        isOpened={isMenuOpened}
        onClose={handleToggleDrawer}
        anchor="right"
      />
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
