import React from "react";
import PropTypes from "prop-types";

const BoldText = (props) => {
  return (
    <span style={{ fontWeight: "bold" }} {...props}>
      {props?.children}
    </span>
  );
};

BoldText.propTypes = {
  children: PropTypes.node,
};

export default BoldText;
