import React from "react";
import PropTypes from "prop-types";
import {
  FooterItem,
  FooterItemsContainer,
  FooterSection,
  FooterTitle,
} from "../Footer.styled";
import { useTranslation } from "react-i18next";
import { headerNavigationConstants } from "constants/navigationConstants";
import { linkVariants } from "constants/linkVariants";

const NavigationSection = () => {
  const { t } = useTranslation();
  return (
    <FooterSection>
      <FooterTitle>{t("navigation.title")}</FooterTitle>
      <FooterItemsContainer>
        {headerNavigationConstants?.map?.((singleRoute) => (
          <FooterItem
            key={singleRoute?.route}
            to={singleRoute?.route}
            variant={linkVariants.whiteInitial}
          >
            {singleRoute?.title}
          </FooterItem>
        ))}
      </FooterItemsContainer>
    </FooterSection>
  );
};

NavigationSection.propTypes = {
  children: PropTypes.node,
};

export default NavigationSection;
