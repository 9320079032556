import TwoParagraphComponent from "components/DataComponents/TwoParagraphComponent/TwoParagraphComponent";
import Slideshow from "components/Slideshow/Slideshow";
import styled from "styled-components";
import ComponentBg from "assets/images/png/component-background.png";

export const HomePageSlideshow = styled(Slideshow)`
  height: 33vw;
`;
export const HouseComponent = styled(TwoParagraphComponent)`
  background-image: url(${ComponentBg}) !important;
  background-color: ${(props) =>
    props?.theme?.colors?.blackTextColor} !important;
  padding-bottom: 230px;
  @media (max-width:600px) {
    padding-bottom: 230px;
  }
`;
export const List = styled.ul``;
export const ListItem = styled.li`
  font-weight: 600;
`;
export const PartnerImage = styled.img`
  /* max-width: 100%;
  height: auto; */
  max-height: 100px;
  max-width: 100%;
  width: auto;

`