import React, { useEffect, useState } from "react";
import { ProgressBarContainer } from "./ProgressBar.styled";

const ProgressBar = () => {
  const [y, setY] = useState(window.scrollY); // storing current scroll bar positiotn
  const [totalY, setTotalY] = useState(); // storing Total Scrollable area
  const [scrollBar, setScrollBar] = useState(); // storing Size of scroll bar

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    const element = document.getElementsByTagName("body")[0];
    setTotalY(element.clientHeight - window.innerHeight);
    setScrollBar(0);
  }, [y]);

  const scroll = `${((y + scrollBar) / totalY) * 100}%`;
  return <ProgressBarContainer style={{ width: scroll }} />;
};

export default ProgressBar;
