import { Box } from "@mui/material";
import styled from "styled-components";

export const TextDropdownContainer = styled(Box)`
  height: 46px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow: hidden;
  transition-duration: 0.2s;
  border-bottom: 1px solid ${(props) => props?.theme?.colors?.borderColor};
`;
export const DropdownHeaderTitle = styled(Box)`
  font-size: 18px;
  color: ${(props) =>
    props?.$isOpened
      ? props?.theme?.colors?.yellowColor
      : props?.theme?.colors?.fontColor};
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.5px;
`;
export const DropdownHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  &:hover {
    & ${DropdownHeaderTitle} {
      color: ${(props) => props?.theme?.colors?.yellowColor};
    }
  }
`;
export const DropdownHeaderIcon = styled(Box)`
  width: 20px;
  height: 20px;
`;
export const DropdownText = styled(Box)`
  font-size: 16px;
  color: ${(props) => props?.theme?.colors?.fontColor};
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
`;
