import { Box } from "@mui/material";
import styled from "styled-components";
import FooterBg from "assets/images/jpg/footer-background.jpg";
import Logo from "components/Logo/Logo";
import SocialLink from "components/SocialHeader/SocialLink/SocialLink";

export const FooterContainer = styled(Box)`
  background-color: ${(props) => props?.theme?.colors?.fontColor};
  background-image: url(${FooterBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 164px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
  @media (max-width: 959px) {
    padding: 40px 40px;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    padding: 40px 30px;
    gap: 40px;
  }
`;
export const LogoImage = styled(Logo)`
  & svg {
    height: 40px;
    @media (max-width: 959px) {
      width: 100%;
      min-width: 100px;
      height: auto;
    }
  }
`;
export const FooterSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 600px) {
    min-width: calc((100vw - 100px) * 0.4);
    flex: 1;
  }
`;
export const FooterTitle = styled(Box)`
  color: ${(props) => props?.theme?.colors?.grayColor};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
`;
export const FooterItem = styled(SocialLink)`
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
export const FooterItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const FooterHalfContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  gap: 40px;
  flex: 1;
  @media (max-width: 959px) {
    gap: 15px;
  }
  @media (max-width: 600px) {
    width: 100%;
    flex-wrap: wrap-reverse;
    gap: 4px;
    flex-direction: row-reverse;
  }
`;
