import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  SingleParagraphContainer,
  SingleParagraphText,
  SingleParagraphTitle,
  HeaderParagraphContainer,
  HeaderParagraphText,
  HeaderParagraphTitle,
  FourParagraphsComponentContainer,
  FourParagraphsContainer,
} from "./FourParagraphsComponent.styled";
import CTAButton from "components/Button/CTAButton/CTAButton";

const FourParagraphsComponent = (props) => {
  const containerRef = useRef(null);
  const [shouldShowText, setShouldShowText] = useState(false);

  const scrollListener = (event) => {
    let scrollTop = event?.target?.scrollingElement?.scrollTop;
    let clientHeight = event?.target?.scrollingElement?.clientHeight;
    let offsetTop = containerRef?.current?.offsetTop;
    let scrollY = window.scrollY + window.innerHeight;
    let shouldShowText;
    if (event) shouldShowText = scrollTop + clientHeight > offsetTop;
    else shouldShowText = scrollY > offsetTop;
    setShouldShowText(shouldShowText);
  };

  useEffect(() => {
    scrollListener();
  }, []);

  useEffect(() => {
    if (!shouldShowText) window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, [containerRef.current, shouldShowText]);

  return (
    <FourParagraphsComponentContainer
      ref={containerRef}
      $bgColor={props?.bgColor}
      $bgImage={props?.bgImage}
      $shouldShowText={shouldShowText}
    >
      <HeaderParagraphContainer
        $shouldShowText={shouldShowText}
        $biggerHeader={props?.biggerHeader}
      >
        {props?.headerParagraphTitle && (
          <HeaderParagraphTitle $biggerTitle={props?.biggerTitle}>
            {props?.headerParagraphTitle}
          </HeaderParagraphTitle>
        )}
        {props?.headerParagraphText && (
          <HeaderParagraphText
            $leftAlign={props?.leftAlign}
            $biggerText={props?.biggerText}
          >
            {props?.headerParagraphText}
          </HeaderParagraphText>
        )}
      </HeaderParagraphContainer>

      {(props?.paragraphs || props?.componentAddon) && (
        <FourParagraphsContainer
          $wrap={props?.wrap}
          $shouldShowText={shouldShowText}
          $alignContainers={props?.alignContainers}
        >
          {props?.componentAddon}
          {props?.paragraphs?.map?.((singleParagraph, index) => (
            <SingleParagraphContainer key={index}>
              {singleParagraph?.title && (
                <SingleParagraphTitle>
                  {singleParagraph?.title}
                </SingleParagraphTitle>
              )}
              {singleParagraph?.text && (
                <SingleParagraphText>
                  {singleParagraph?.text}
                </SingleParagraphText>
              )}
            </SingleParagraphContainer>
          ))}
        </FourParagraphsContainer>
      )}
      {props?.buttonText && (
        <CTAButton to={props?.buttonLink}>{props?.buttonText}</CTAButton>
      )}
    </FourParagraphsComponentContainer>
  );
};

FourParagraphsComponent.propTypes = {
  bgImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  paragraphs: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.node,
      ]),
      text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.node,
      ]),
    })
  ),
  buttonClick: PropTypes.func,
  headerParagraphTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  headerParagraphText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  bgColor: PropTypes.string,
  buttonLink: PropTypes.string,
  leftAlign: PropTypes.bool,
  biggerHeader: PropTypes.bool,
  biggerTitle: PropTypes.bool,
  alignContainers: PropTypes.bool,
  biggerText: PropTypes.bool,
  wrap: PropTypes.bool,
  componentAddon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
};

export default FourParagraphsComponent;
