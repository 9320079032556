import { Box } from "@mui/material";
import styled from "styled-components";

export const ProgressBarContainer = styled(Box)`
  height: 4px;
  background-color: #F39314;
  overflow: hidden;
  position: fixed;
  transition: width 0.5s;
  z-index: 200;
  top: 134px
`;
