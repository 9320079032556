import React from "react";
import PropTypes from "prop-types";
import ButtonLink from "../ButtonLink/ButtonLink";
import { INSTAGRAM_PAGE } from "constants/linkConstants";
import { InstagramIcon } from "./InstagramButtonLink.styled";

const InstagramButtonLink = () => {
  return <ButtonLink icon={<InstagramIcon />} to={INSTAGRAM_PAGE} />;
};

InstagramButtonLink.propTypes = {
  children: PropTypes.node,
};

export default InstagramButtonLink;
