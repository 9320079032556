import { Box } from "@mui/material";
import LeftBuilding from "assets/images/jpg/left-building.jpg";
import styled from "styled-components";

export const LeftHouseImageContainer = styled(Box)`
  width: 50%;
  height: 100%;
  position: absolute !important;
  opacity: 1 !important;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  transition-duration: 0.3s !important;
  background-image: url(${LeftBuilding});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 600px) {
    height: 300px;
    width: 100%;
    top: initial;
    left: 0;
    /* margin: 0 auto; */
  }
`;
