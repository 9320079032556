import React from "react";
import PropTypes from "prop-types";
import ButtonLink from "../ButtonLink/ButtonLink";
import { FACEBOOK_PAGE } from "constants/linkConstants";
import { FacebookIcon } from "./FacebookButtonLink.styled";

const FacebookButtonLink = () => {
  return <ButtonLink icon={<FacebookIcon />} to={FACEBOOK_PAGE} />;
};

FacebookButtonLink.propTypes = {
  children: PropTypes.node,
};

export default FacebookButtonLink;
