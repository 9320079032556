import { Box } from "@mui/material";
import styled from "styled-components";

export const TeamMemberCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const TeamMemberCardImageContainer = styled(Box)`
  width: 50px;
  background-image: ${(props) => `url(${props?.$bgImage})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const TeamMemberImageContainer = styled(Box)`
  background-color: #c7c7c7;
  display: flex;
  height: auto;
  aspect-ratio: 16/9;
  width: 100%;
`;
