import React from "react";
import {
  ReferenzenContentContainer,
  ReferenzenPageLayout,
} from "./ReferenzenPage.styled";
import BannerBg from "assets/images/banner/bgImpress.jpg";
import Banner from "components/Banner/Banner";
import Gallery from "components/Gallery/Gallery";
import { categories } from "./categories";
import { galleryItems } from "./galleryItems";

const ReferenzenPage = () => {
  const BannerItem = [
    {
      bgImage: BannerBg,
      index: 0,
    },
  ];

  return (
    <ReferenzenPageLayout>
      <Banner slideshowElements={BannerItem} />
      <ReferenzenContentContainer>
        <Gallery categories={categories} images={galleryItems} />
      </ReferenzenContentContainer>
    </ReferenzenPageLayout>
  );
};

export default ReferenzenPage;
