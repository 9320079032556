import Instagram from "components/Icons/InstagramIcon";
import styled from "styled-components";

export const InstagramIcon = styled(Instagram)`
  width: 15px;
  height: 15px;
  & * {
    fill: #fff;
  }
`;
