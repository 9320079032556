import { Box } from "@mui/material";
import styled, { css } from "styled-components";

export const TwoParagraphComponentContainer = styled(Box)`
  padding: 165px 40px;
  background-image: ${(props) => `url(${props?.$bgImage})`};
  background-color: ${(props) =>
    props?.$bgColor ? props?.$bgColor : props?.theme?.colors?.blackTextColor};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  ${(props) =>
    props?.$light &&
    css`
      & ${SingleParagraphTitle} {
        color: ${(props) => props?.theme?.colors?.whiteColor};
      }
      & ${SingleParagraphText} {
        color: ${(props) => props?.theme?.colors?.whiteColor};
      }
    `}
  & > div {
    opacity: 0;
    position: relative;
    bottom: -10px;
    transition-delay: 0.1s;
    transition-duration: 0.5s;
    ${(props) =>
      props?.$shouldShowText &&
      css`
        bottom: 0;
        opacity: 1;
      `}
  }
  @media (max-width: 959px) {
    padding: 100px 40px;
  }
  @media (max-width: 600px) {
    padding: 40px 30px;
    flex-direction: column;
    background-image: none;
    background-color: ${(props) =>
      props?.theme?.colors?.componentBackgroundColor1};
  }
`;
export const SingleParagraphContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  max-width: 550px;
`;
export const SingleParagraphTitle = styled(Box)`
  font-weight: 700;
  letter-spacing: 1px;
  color: ${(props) => props?.theme?.colors?.fontColor};
  line-height: 1.1;
  font-size: 36px;
  @media (max-width: 959px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;
export const SingleParagraphText = styled(Box)`
  font-size: 12pt;
  color: ${(props) => props?.theme?.colors?.fontColor};
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
  padding-bottom: 15px;
`;
export const ParagraphsContentContainer = styled(Box)`
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 70px;
  width: 100%;
  & > div {
    opacity: 0;
    position: relative;
    bottom: -10px;
    transition-delay: 0.1s;
    transition-duration: 0.5s;
    ${(props) =>
      props?.$shouldShowText &&
      css`
        bottom: 0;
        opacity: 1;
      `}
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
export const HeaderParagraphContainer = styled(SingleParagraphContainer)`
  max-width: 1270px;
  text-align: center;
`;
