import { Box } from "@mui/material";
import PageLayout from "layouts/PageLayout";
import styled from "styled-components";

export const AGBPageLayout = styled(PageLayout)`
  display: flex;
  gap: 40px;
  //align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
`;

export const AGBContentContainer = styled(Box)`
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

export const SingleParagraphTitle = styled(Box)`
  font-weight: 700;
  letter-spacing: 1px;
  color: ${(props) => props?.theme?.colors?.fontColor};
  line-height: 1.1;
  font-size: 22px;
  text-align: justify;
  @media (max-width: 959px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const SingleParagraphText = styled(Box)`
  font-size: 12pt;
  color: ${(props) => props?.theme?.colors?.fontColor};
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
  //margin-top: 16px;
  margin-bottom: 16px;
`;

export const AGBTextWrapper = styled(Box)`
  margin-top: 16px;
`
