import { Box } from "@mui/material";
import styled, { css } from "styled-components";

export const FourParagraphsComponentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 70px 40px;
  gap: 40px;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props?.$bgColor};
  background-image: ${(props) => `url(${props?.$bgImage})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media (max-width: 959px) {
    padding: 40px 30px;
  }
`;
export const SingleParagraphContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;
export const FourParagraphsContainer = styled(Box)`
  display: flex;
  gap: 40px;
  width: 100%;
  max-width: 1280px;
  justify-content: center;
  ${(props) =>
    props?.$alignContainers &&
    css`
      & > div {
        align-items: center;
        margin-bottom: 20px;
      }
    `}
  & > div {
    opacity: 0;
    position: relative;
    bottom: -10px;
    ${(props) =>
      props?.$shouldShowText &&
      css`
        bottom: 0;
        opacity: 1;
      `}
  }
  & > div:nth-child(1) {
    transition-delay: 0.2s;
    transition-duration: 0.5s;
  }
  & > div:nth-child(2) {
    transition-delay: 0.3s;
    transition-duration: 0.5s;
  }
  & > div:nth-child(3) {
    transition-delay: 0.4s;
    transition-duration: 0.5s;
  }
  & > div:nth-child(4) {
    transition-delay: 0.5s;
    transition-duration: 0.5s;
  }
  @media (max-width: 959px) and (min-width: 600px) {
    flex-wrap: wrap;
    & ${SingleParagraphContainer} {
      flex-basis: 40%;
      max-width: 49%;
    }
    gap: 30px;
  }
  @media (max-width: 600px) {
    ${(props) =>
      props?.$wrap
        ? css`
            flex-wrap: wrap;
            & ${SingleParagraphContainer} {
              flex-basis: 40%;
              max-width: 49%;
            }
          `
        : css`
            flex-wrap: nowrap;
            flex-direction: column;
          `}

    gap: 20px;
  }
`;
export const SingleParagraphTitle = styled(Box)`
  font-weight: 700;
  letter-spacing: 1px;
  color: ${(props) => props?.theme?.colors?.fontColor};
  line-height: 1.1;
  font-size: 22px;
  @media (max-width: 959px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
export const SingleParagraphText = styled(Box)`
  font-size: 12pt;
  color: ${(props) => props?.theme?.colors?.fontColor};
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
`;
export const HeaderParagraphContainer = styled(SingleParagraphContainer)`
  max-width: ${(props) => (props?.$biggerHeader ? "900px" : "760px")};
  text-align: center;
  opacity: 0;
  position: relative;
  bottom: -10px;
  transition-delay: 0.1s;
  transition-duration: 0.5s;
  ${(props) =>
    props?.$shouldShowText &&
    css`
      bottom: 0;
      opacity: 1;
    `}
  @media (max-width: 959px) {
    max-width: none;
    width: 100%;
  }
`;
export const HeaderParagraphTitle = styled(SingleParagraphTitle)`
  ${(props) =>
    props?.$biggerTitle
      ? css`
          font-size: 52px;
          margin-bottom: 15px;
        `
      : css`
          font-size: 36px;
        `};
  @media (max-width: 959px) {
    ${(props) =>
      props?.$biggerTitle
        ? css`
            font-size: 40px;
            margin-bottom: 15px;
          `
        : css`
            font-size: 32px;
          `};
  }
  @media (max-width: 600px) {
    ${(props) =>
      props?.$biggerTitle
        ? css`
            font-size: 32px;
          `
        : css`
            font-size: 26px;
          `};
  }
`;
export const HeaderParagraphText = styled(SingleParagraphText)`
  text-align: ${(props) => props?.$leftAlign && "left"};
  ${(props) =>
    props?.$biggerText &&
    css`
      font-size: 18px;
    `}
`;
