import React from "react";
import PropTypes from "prop-types";
import {
  HeaderCustomDrawerContainer,
  HeaderDrawerText,
  HeaderHamburgerLines,
  HeaderHamburgerSingleLine,
} from "./HeaderCustomDrawer.styled";
//import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectIsMenuOpened, setIsMenuOpened } from "features/app/appSlice";

const HeaderCustomDrawer = () => {
  //const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMenuOpened = useSelector(selectIsMenuOpened);

  const handleToggleDrawer = (event) => {
    event.stopPropagation();
    dispatch(setIsMenuOpened());
  };

  return (
    <HeaderCustomDrawerContainer onClick={handleToggleDrawer}>
      <HeaderDrawerText></HeaderDrawerText> { /*t("menu.menu")*/}
      <HeaderHamburgerLines $isOpened={isMenuOpened}>
        <HeaderHamburgerSingleLine />
        <HeaderHamburgerSingleLine />
        <HeaderHamburgerSingleLine />
      </HeaderHamburgerLines>
    </HeaderCustomDrawerContainer>
  );
};

HeaderCustomDrawer.propTypes = {
  children: PropTypes.node,
};

export default HeaderCustomDrawer;
