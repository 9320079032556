import { Box } from "@mui/material";
import Arrow from "components/Arrow/Arrow";
import styled, { css } from "styled-components";
import { hexToRGB } from "util/helpers/colorHelpers";

export const SlideshowContainer = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-height: 200px;
  @media (max-width: 600px) {
    justify-content: space-between;
    padding: 0 15px;
    min-height: 0;
  }
`;
export const SlideshowImageContainer = styled(Box)`
  height: 100%;
  background-image: ${(props) => `url(${props?.$bgImage})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition-duration: 1s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  ${(props) =>
    props?.$isShown &&
    css`
      opacity: 1;
    `}
`;
export const SlideshowElementContainer = styled(Box)`
  flex: 1;
  height: 100%;
  position: relative;
  @media (max-width: 600px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 33vw;
  }
`;
export const ArrowIcon = styled(Arrow)`
  width: 30px;
  height: 30px;
  border-color: ${(props) =>
    hexToRGB(props?.theme?.colors?.blackTextColor, 0.5)} !important;
  & polyline {
    stroke: ${(props) =>
      hexToRGB(props?.theme?.colors?.blackTextColor, 0.5)} !important;
  }
  &:hover {
    border-color: ${(props) =>
      hexToRGB(props?.theme?.colors?.blackTextColor, 1)} !important;
    & polyline {
      stroke: ${(props) =>
        hexToRGB(props?.theme?.colors?.blackTextColor, 1)} !important;
    }
  }
  @media (max-width: 600px) {
    z-index: 101;
    position: relative;
    border-color: ${(props) =>
      hexToRGB(props?.theme?.colors?.whiteColor, 0.5)} !important;
    & polyline {
      stroke: ${(props) =>
        hexToRGB(props?.theme?.colors?.whiteColor, 0.5)} !important;
    }
    &:hover {
      border-color: ${(props) =>
        hexToRGB(props?.theme?.colors?.whiteColor, 1)} !important;
      & polyline {
        stroke: ${(props) =>
          hexToRGB(props?.theme?.colors?.whiteColor, 1)} !important;
      }
    }
  }
`;
