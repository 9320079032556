import React from "react";
import PropTypes from "prop-types";
import { PageTitleContainer } from "./PageTitle.styled";

const PageTitle = (props) => {
  return (
    <PageTitleContainer className={props?.className}>
      {props?.children}
    </PageTitleContainer>
  );
};

PageTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
};

export default PageTitle;
