import { Box } from "@mui/material";
import PageLayout from "layouts/PageLayout";
import styled from "styled-components";

export const ReferenzenPageLayout = styled(PageLayout)`
  display: flex;
  gap: 40px;
  flex-direction: column;
  padding-bottom: 40px;
`;

export const ReferenzenContentContainer = styled(Box)`
  padding-left: 40px;
  padding-right: 40px;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;