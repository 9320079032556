import { Box } from "@mui/material";
import ContactSection from "components/Footer/ContactSection/ContactSection";
import {
  FooterItem,
  FooterItemsContainer,
  FooterTitle,
} from "components/Footer/Footer.styled";
import Link from "components/Link/Link";
import styled from "styled-components";

export const ImpressumContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;
  @media (max-width: 959px) {
    padding: 40px 30px;
  }
  @media (max-width: 600px) {
    gap: 20px;
  }
`;
export const ImpressumTitle = styled(Box)`
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.1;
  font-size: 36px;
  text-align: center;
  color: #002546;
  @media (max-width: 959px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

export const ImpressumContentContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ImpressumContentTitle = styled(Box)`
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  color: #002546;
  line-height: 1.1;
  font-size: 1.25rem;
  margin: 20px;
`;

export const ImpressumSingleParagraphText = styled(Box)`
  font-size: 12pt;
  color: #002546;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
`;

export const ImpressumLinkContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px 0;
`;

export const ImpressumLink = styled(Link)`
  color: #8ea1bd;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
  cursor: pointer;
  &:hover {
    color: #d5ad00;
  }
`;

export const ImpressumComponentContainer = styled(ContactSection)`
  text-align: center;
  & ${FooterTitle} {
    color: ${(props) => props?.theme?.colors?.blackTextColor};
    font-size: 20px;
    text-transform: none;
  }
  & ${FooterItem} {
    color: ${(props) => props?.theme?.colors?.blackTextColor};
  }
  & ${FooterItemsContainer}:nth-child(3) {
    & ${FooterItem} {
      color: ${(props) => props?.theme?.colors?.grayColor};
      &:hover {
        color: ${(props) => props?.theme?.colors?.yellowColor};
      }
    }
  }
`;
