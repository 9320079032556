import Link from "components/Link/Link";
import styled from "styled-components";
import { hexToRGB } from "util/helpers/colorHelpers";

export const CTAButtonContainer = styled(Link)`
  font-size: 16px;
  color: ${(props) => props?.theme?.colors?.whiteColor};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: ${(props) => props?.theme?.colors?.greenColor};
  padding: 15px 15px;
  transition: all 0.3s ease-in-out;
  width: fit-content;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      hexToRGB(props?.theme?.colors?.greenColor, 0.7)};
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
