/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Dialog, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSwipeable } from "react-swipeable";

const ImageCarousel = ({ open, handleClose, images, initialIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  useEffect(() => {
    if (initialIndex !== null) {
      setCurrentIndex(initialIndex);
    }
  }, [initialIndex]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrevious();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
  });

  const currentImageDetails = images[currentIndex];

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={true}>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "black",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8, color: "white" }}
        >
          <CloseIcon />
        </IconButton>
        <IconButton
          onClick={handlePrevious}
          sx={{ position: "absolute", top: "50%", left: 8, color: "white" }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          onClick={handleNext}
          sx={{ position: "absolute", top: "50%", right: 8, color: "white" }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
        <Box
          {...swipeHandlers}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            touchAction: "none", // to ensure swipe gestures are recognized
          }}
        >
          <img
            src={currentImageDetails?.image}
            alt={currentImageDetails?.installationLocation}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </Box>
        <Box
          sx={{ position: "absolute", bottom: 5, color: "white", fontSize: 18 }}
        >
          <p>{currentImageDetails?.installationLocation}</p>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ImageCarousel;