import React from "react";
import PropTypes from "prop-types";

const WhiteColor = (props) => {
  return (
    <span style={{ color: "white" }} {...props}>
      {props?.children}
    </span>
  );
};

WhiteColor.propTypes = {
  children: PropTypes.node,
};

export default WhiteColor;
