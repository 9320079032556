export const primaryThemeColors = {
  primaryDark: "#393646",
  secondaryDark: "#4F4557",
  secondaryLighter: "#6D5D6E",
  primaryLighter: "#836E80",
  textColor: "#C4DFDF",
  grayColor: "#8ea1bd",
  yellowColor: "#F39210",//"#d5ad00",
  whiteColor: "#ffffff",
  fontColor: "#002546",
  blackTextColor: "#002647",
  separatorBannerColor: "#CDD8E6",
  blackColor: "#000000",
  componentBackgroundColor1: "#F2F6F9",
  greenColor: "#243E79",//"#3e9cab",
  borderColor: "#707070",
  waterColor: "#0060b3"
};
