export default {
  app: {
    title: "React template",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    language: "Language",
    english: "English",
    german: "Deutsch",
    serbian: "Serbisch",
    close: "Close",
    dataGridExample: "Data Grid Example",
    trademark: "TM",
    search: "Search",
    error: "Error",
    continue: "Continue",
    labelUsername: "Username",
    labelEmail: "Email",
    labelPassword: "Password",
    next: "Next",
    nextPage: "Next page",
    previousPage: "Previous page",
    back: "Back",
    goBack: "Go Back",
    ok: "Ok",
    done: "Done",
    confirm: "Confirm",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
    all: "Alle",
    installationLocation: "Installationsort",
    installedCapacity: "Installierte Leistung",
    installedModules: "Installierte Module",
    installation: "Inbetriebnahme"
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  menu: {
    menu: "Menü",
  },
  pages: {
    contact: "Kontakt",
    photovoltaic: "Photovoltaik",
    impressum: "Impressum",
    services: "Dienstleistungen",
    team: "Team",
    agb: "AGB",
    referenzen: "Referenzen"
  },
  contact: {
    title: "Kontakt",
  },
  navigation: {
    title: "Navigation",
  },
  informations: {
    title: "Information",
  },
  header: {
    logoText: "DIE SONNE LIEFERT, WIR SPEICHERN",
  },
  bottomNavigation: {
    item1: {
      title: "Aufdach",
    },
    item2: {
      title: "Indach",
    },
    item3: {
      title: "Flachdach",
    },
    item4: {
      title: "Solarchitektur",
    },
  },
  homePage: {
    banner: {
      items: {
        text1: `Professionelle<br/>
          Photovoltaikanlagen.`,
        button1: "Zur Photovaltik",
        text2: `Unsere Solarwelt –<br/>
          alles was Sie wissen wollen.`,
        button2: "Zur Solarwelt",
        text3: "Zukunft, mobil, unabhängig.",
        button3: "Wir beraten Sie gerne",
        text4: `Wertvolle Solarenergie<br/>
          rund um die Uhr nutzen.`,
        button4: "Smarte-haustechnik",
      },
    },
    paragraph1: {
      title: "Mit der richtigen <br />Lösung nachhaltig <br />Strom gewinnen.",
      text: "Produzieren Sie jetzt Ihren eigenen Strom! Photovoltaik ist die lohnendste Investition in eine nachhaltige Energiezukunft. Sparen Sie Geld, indem Sie Ihre eigene Energie nutzen. 4 Gründe für ein persönliches Sonnenkraftwerk. <ul><li>Wertsteigerung Ihrer Liegenschaft</li><li>Staatliche Förderung</li> <li>Beitrag zur Nachhaltigkeit</li> <li>Reduktion Ihrer Stromkosten</li></ul>",
      buttonText: "Mehr über uns",
    },
    paragraph2: {
      title: "Unsere Dienstleistungen",
      text: `Solarenergie ist zweifellos eine wertvolle Ressource, die effizient genutzt werden kann. Wir bieten umfassende Beratung und Begleitung auf dem Weg zum Stromproduzenten und
      unterstützen Sie dabei, den ökologischen Mehrwert durch Verbrauchsoptimierung und Energiespeicherung maximal auszuschöpfen. Durch die Implementierung von
      Gebäudeautomation und eine kontinuierliche Betreuung während und nach der Umsetzung ermöglichen wir Ihnen eine reibungslose Umsetzung Ihres Projekts.`,
      littleParagraphs: {
        paragraph1: {
          title: "Planung & Beratung",
          text: `Mit unserem Wissen und Erfahrung entwickeln wir die individuelle und perfekte Lösung für Sie und Ihr Projekt.`,
        },
        paragraph2: {
          title: "Installation",
          text: `Wir sind leidenschaftliche Handwerker und kümmern uns gerne um die professionelle Installation Ihrer Anlage gemäß höchster Standards. Wir übernehmen die Koordination mit Schnittstellen und sorgen dafür, dass das Projekt reibungslos abläuft.`,
        },
        paragraph3: {
          title: "Smarte-Haustechnik",
          text: `Eine effiziente Nutzung von Energie ist entscheidend für die wirtschaftliche Rentabilität einer Anlage. Durch die lokale und bedarfsgerechte Nutzung Ihres Stroms können Sie diesen optimal einsetzen.`,
        },
        paragraph4: {
          title: "Unterhalt & Service",
          text: `Wir stehen unseren Kunden auch nach der Umsetzung zur Verfügung und gewährleisten einen reibungslosen Betrieb Ihrer Anlage.`,
        },
      },
      buttonText: "Zu Unseren Leistungen",
    },
    paragraph3: {
      buttonText: "Zum Solarrechner",
      title: `Tauchen Sie ein in die Welt der Photovoltaik.`,
      text: `In unserem Unternehmen verschmelzen Ästhetik und Funktionalität zu einzigartigen Lösungen. Lassen
      Sie sich von unserer langjährigen Erfahrung, unserer Leidenschaft und unseren technologischen
      Innovationen überzeugen, die individuelle Antworten für Ihr Projekt ermöglichen. Erleben Sie das volle Spektrum von Leistung, einer Vielfalt an Farben, Formen und Materialien. Nutzen Sie die Flächen auf subtile oder auffällige Weise, und entdecken Sie neue Möglichkeiten.`,
    },
    paragraph4: {
      buttonText: "Zu Smarte Haustechnik",
      title: "Smarte Haustechnik",
      text: `Die Integration von smarter Haustechnik kann den Nutzwert von Solarstrom erheblich steigern. Durch eine intelligente Steuerung können Sie das volle Potenzial Ihrer Photovoltaik-Anlage zeitunabhängig ausschöpfen. Die Auswahl des richtigen Energiespeichers ermöglicht eine maximale Nutzung, selbst bei geringer Sonneneinstrahlung. <br /> <br />Durch den Einsatz von stationären Batterien oder Akkumulatoren von Elektrofahrzeugen können Sie Ihre Autarkie erhöhen. Gerne besprechen wir mit Ihnen Ihre spezifischen Anforderungen und erarbeiten ein maßgeschneidertes Konzept, um den maximalen Nutzen aus der Energie Ihrer Photovoltaik-Anlage zu erzielen.`,
      iconTitle1: "Speicherlösungen",
      iconTitle2: "Warmwasser",
      iconTitle3: "E-Mobiliät",
      iconTitle4: "ZEV",
    },
    paragraph5: {
      title: "Unsere Referenzen",
      buttonText: "Alle Referenzen",
    },
    paragraph6: {
      title: "Unsere Partner",
      text: "Wir bauen auf konstruktive Zusammenarbeit mit unseren Partnern, für Ihr bestmögliches Ergebnis.",
    },
    paragraph7: {
      title: "Wir beraten Sie gerne!",
      text: "Alle Fragen über Photovoltaik von der Baueingabe bis zur Installation oder Wartung beantworten wir Ihnen gerne.",
      buttonText: "Kontaktieren",
    },
  },
  impressumPage: {
    title: "Impressum",
    paragraph1: {
      title: "Gesamtverantwortung"
    }, 
    paragraph2: {
      title: "Konzept & Gestaltung"
    }, 
    paragraph3: {
      title: "Programmierung"
    }, 
  },
  contactPage: {
    banner: {
      title: "Die Energie<br />der Zukunft ist lokal.",
    },
    paragraph1: {
      title: "Nehmen Sie mit uns Kontakt auf. <br />Wir stehen Ihnen gerne zur Verfügung.",
    },
  },
  photovoltaicPage: {
    banner: {
      title: "Die Zukunft <br />liegt in der Gebäudehülle.",
    },
    paragraph1: {
      title: "Photovoltaikanlagen",
      text: "Bei der Photovoltaik (PV) wird die Sonnenstrahlung in Solarzellen direkt und emissionsfrei in elektrische Energie umgewandelt. Immer mehr Länder setzen auf umweltfreundlichen und günstigen Strom der Sonne. In der Schweiz beträgt die Sonneneinstrahlung auf die Gesamtfläche 200mal mehr als genutzt wird. PV-Anlagen sind bewährte und zukunftsweisende Technologien zur Stromerzeugung. Nutzen Sie das Potenzial der SOLARenergie.",
    },
    paragraph2: {
      title: "Indach",
      subtitle: "Neubau & Dachsanierungen",
      text: "Die Photovoltaikmodule können perfekt ins Dach integriert werden. Dabei gilt es sowohl Farbe als auch Form und Struktur auf die Dacheigenschaften abzustimmen, Leerflächen mit Blindmodulen zu ergänzen und ein stimmiges Gesamtbild zu schaffen. Auch unter Einbezug der Denkmalpflege können Anlagen optimal in das Erscheinungsbild des Gebäudes eingebunden werden.",
      dropDownData: [
        {
          dropdownTitle: "Dachsanierung",
          dropdownText: "Mit unseren langjährigen Fachpartnern unterstützen wir Sie bei der Sanierung Ihres Daches. Wir fusionieren Wissen der Photovoltaik mit dem der Gebäudehülle. Treffen Sie nachhaltige Massnahmen, denn so können Sie von attraktiven Förderungen profitieren.",
        },
      ],
    },
    paragraph3: {
      title: "Aufdach",
      subtitle: "Für jedes Dach!",
      text: "Bei bestehenden Dächern werden PV-Anlagen auf die vorhandene Eindeckung montiert. Dabei werden vorwiegend schwarze Komponenten verbaut, um ein ruhiges und unauffälligeres Anlagebild zu erwirken. Dachfenster, Kamine und Dunstrohre werden mit Blindelementen eingefasst und integriert.",
      dropDownData: [
        {
          dropdownTitle: "Downloads",
          dropdownText: "folgen",
        },
      ],
    },
    paragraph4: {
      title: "Flachdach",
      subtitle: "EFH",
      text: "Flachdächer eignen sich hervorragend für die Installation von PV-Anlagen. Man kann die Neigung und Ausrichtung der Module nach Bedarf optimieren, um die bestmögliche Sonneneinstrahlung zu gewährleisten. Auf diese Weise lässt sich die gesamte Dachfläche effizient nutzen, ohne strukturelle Einschränkungen durch die Dachform. Das macht sie zu einer äußerst attraktiven Option für die Nutzung von Solarenergie.",
      dropDownData: [
        {
          dropdownTitle: "Industrieanlagen",
          dropdownText:
            "Durch eine Steigerung der Energieeffizienz können Sie erhebliche Kosten einsparen. Sie produzieren Strom zu günstigeren Preisen als die Versorgung durch den Netzbetreiber und verbessern gleichzeitig die CO2-Bilanz und Wirtschaftlichkeit Ihres Unternehmens. Als fortschrittliches Unternehmen leisten Sie einen wichtigen Beitrag zur Energiezukunft. Ihr Engagement und Ihre Modernität beeinflussen maßgeblich die Realisierung des Ziels einer klimaneutralen Schweiz bis 2050.",
        },
      ],
    },
    paragraph5: {
      title: "Solarchitektur",
      subtitle: "Für jede Anforderung eine Lösung!",
      text: "Neben Dächern eignen sich auch Fassaden, Stützmauern und Carports perfekt für die Stromerzeugung. Mit unserer langjährigen Erfahrung entwickeln wir Lösungen, die ästhetisch sowie technisch überzeugen und dem Einsatzgebiet entsprechen.<br /><br />Bauen Sie mit PV und schaffen Sie mehr als eine simple Kombination von Architektur und Technologie: Effizient und architektonisch harmonisch zugleich.",
      dropDownData: [
        {
          dropdownTitle: "Downloads",
          dropdownText: "folgen",
        },
      ],
    },
  },
  servicesPage: {
    banner: {
      title: "Ihr Projekt – unsere Kompetenz.",
    },
    paragraph1: {
      title: "Dienstleistungen",
      text: "Seit dem Jahr 2023 sind wir erfolgreich in der Planung, Installation und Wartung von Photovoltaik-Anlagen tätig. Wir bieten umfassende Unterstützung sowohl bei der Umsetzung als auch beim Engineering, der Ausschreibung oder der Fachbauleitung. Gerne beraten wir Sie, welche unserer Dienstleistungen am besten zu Ihnen und Ihrem Projekt passen. ",
    },
    paragraph2: {
      title: "Unsere Kunden",
      subtitle: "Planung & Beratung",
      text: "Unser Unternehmen bietet umfassende Planungs- und Beratungsdienstleistungen für Bauherren, Architekten und Gesamtunternehmen an. Wir analysieren das Potenzial eines Objekts, um ein optimales Gesamtkonzept zu entwickeln. Dabei berücksichtigen wir Ihre individuellen Bedürfnisse und den Stromverbrauch, um die Haustechnik entsprechend anzupassen. Unser Ziel ist es, ein wirtschaftlich interessantes Konzept mit ästhetischen Lösungen zu schaffen. ",
      dropDownData: [
        {
          dropdownTitle: "Bewilligungen & Fördergelder",
          dropdownText:
            "Wir übernehmen die notwendigen Bewilligungen, wissen, was es braucht und in welcher Form. Dafür stehen wir in Kontakt mit den Baubehörden und Energieversorgungsunternehmen.<br /><br /> Sichern Sie sich die Einmalvergütung vom Bund und individuelle Fördergelder von Kanton und Gemeinde. Wir kümmern uns für Sie um notwendige Anträge. Hier finden Sie eine Übersicht sämtlicher Fördergelder u.a. Steuererleichterungen.",
        },
      ],
    },
    paragraph3: {
      title: "Unsere Kunden",
      subtitle: "Installation & Ausführung",
      text: "Unser Fachpersonal zeichnet sich durch Liebe zum Detail und höchste Sorgfalt aus, wenn es um die Installation und Ausführung geht. Die Installation am Objekt erfordert eine optimale Abstimmung der Details. Basierend auf einer sorgfältigen Planung werden die Spengler- und Dachdeckerarbeiten synchronisiert. Die elektrischen Komponenten des Photovoltaiksystems, wie Modulmontage, Wechselrichter und Speicher, werden professionell in Betrieb genommen.",
      dropDownData: [
        {
          dropdownTitle: "Schnittstellen-Koordination",
          dropdownText:
            "Für unsere Kunden übernehmen wir die Leitung vor Ort, koordinieren Schnittstellen der Gewerke und Handwerkenden. Wir sorgen für einen reibungslosen Projektablauf.",
        },
        {
          dropdownTitle: "Absturzsicherungen",
          dropdownText:
            "Garantiert sichere und unfallfreie Begehung der Anlage. Fachgerechte Planung, Installation und Instandhaltung. Nach den aktuellsten Normen und Standards der SUVA, um das Leben und die Gesundheit unserer Mitarbeitenden zu schützen.",
        },
        {
          dropdownTitle: "Fachpersonal",
          dropdownText:
            "Wir schulen unsere Mitarbeitenden und bleiben auf dem neusten Stand. So werden wir unserem hohen Qualitätsanspruch gerecht. ",
        },
      ],
    },
    paragraph4: {
      title: "Unsere Kunden",
      subtitle: "Smarte-Haustechnik",
      text: "Durch die Integration von smarter Haustechnik können Sie Ihre lokale Energie effizient nutzen. Innovative Speicherlösungen und die Steuerung des nachhaltig gewonnenen Stroms ermöglichen es Ihnen, Ihren Eigenverbrauch und Ertrag zu maximieren. Durch die intelligente Nutzung von Solarstrom können Sie unabhängiger werden.",
      dropDownData: [
        {
          dropdownTitle: "E-Mobilität",
          dropdownText:
            "Tanken Sie Sonne! Fahren Sie in eine umweltfreundliche Zukunft und tanken Sie Ihren lokalen Strom. Durch stationäre Batterien oder Akkumulatoren von Elektromobilen können Sie den Autarkiegrad steigern. Steuern, überwachen und optimieren Sie Ihren Stromverbrauch.",
        },
        {
          dropdownTitle: "ZEV",
          dropdownText:
            "Zusammenschluss Eigenverbrauch <br /><br /> " +
            "Künftig effizientere Gebäude durch Deckung des Strombedarfs durch Eigenverbrauch. Stellen Sie als Besitzer:in den lokal produzierten Solarstrom allen Bewohnenden zur Verfügung. Profitieren Sie von einem rentablen Konzept und die Mietenden von günstigerem Strom, als Strom zum Netztarif.",
        },
      ],
    },
    paragraph5: {
      title: "Unsere Kunden",
      subtitle: "Unterhalt & Nachbetreuung",
      text: "Unser professionelles Überwachungssystem gewährleistet eine zuverlässige Überwachung für professionell gebaute Anlagen. Durch kontinuierliches Monitoring stellen wir langfristige Wirtschaftlichkeit sicher. Es ist auch ratsam, eine Zustandsanalyse älterer Anlagen durchzuführen und ein Unterhalts- und Modernisierungskonzept zu erstellen, um deren Effizienz zu optimieren. ",
      dropDownData: [
        {
          dropdownTitle: "Anlageüberwachung",
          dropdownText:
            "Überwachung aus der Ferne ist mittels Monitoring möglich. Ertragskontrollen sowie Strom- und Eigenverbrauch können visualisiert werden. Diese Visualisierungen der Speicher- und Smart-Grid-Komponenten lassen uns ihr Optimierungspotenzial aufzeigen und Ausfälle schnell beheben.",
        },
        {
          dropdownTitle: "Wartung und Reinigung",
          dropdownText:
            "Optimale Erträge können gesichert und reibungsloser Betrieb der Anlage gewährleistet werden:<br /><br />" +
            "Prüfung der Installation" +
            "Messungen der Funktionalität der Module" +
            "Überprüfung der elektrischen Anschlüsse" +
            "Reinigungen nach Bedarf",
        },
      ],
    },
  },
  teamPage: {
    banner: {
      title: "Solarfachwissen<br/>unter einem Dach.",
    },
    paragraph1: {
      title: "Unsere Unternehmensphilosophie: Energie für eine Nachhaltige Zukunft",
      text: "Bei MegaWatt Solar AG sind wir stolz darauf, eine umfassende Erfahrung in der Solarindustrie mit einer leidenschaftlichen Verpflichtung zur nachhaltigen Energieerzeugung zu verbinden. Unsere Wurzeln reichen tief in die Geschichte erneuerbarer Energien, und wir haben uns stets bemüht, innovative Lösungen anzubieten, um eine grünere Zukunft zu gestalten.",
    },
    paragraph2: {
      text: "<span>Erfahrung:</span><br/>" +
      "Unsere Erfahrung in der Solarbranche bildet das Fundament unseres Wissens und unserer Fähigkeiten. Wir haben zahlreiche Projekte erfolgreich umgesetzt und dabei nicht nur technisches Know-how, sondern auch ein tiefes Verständnis für die Bedürfnisse unserer Kunden entwickelt. Diese Erfahrung ermöglicht es uns, maßgeschneiderte Solarlösungen anzubieten, die höchsten Qualitätsstandards entsprechen.<br/>" +
      "<br/><br/>" +
      "<span>Leidenschaft:</span><br/>" +
      "Unsere Leidenschaft für saubere Energie treibt uns an, jeden Tag unser Bestes zu geben. Bei MegaWatt Solar AG glauben wir fest daran, dass saubere Energie nicht nur eine Notwendigkeit, sondern auch eine Chance ist, positive Veränderungen herbeizuführen. Diese Leidenschaft spiegelt sich in unserem engagierten Team wider, das sich leidenschaftlich für die Umsetzung nachhaltiger Projekte einsetzt.",
    },
    paragraph3: {
      text: "<span>Zukunftsorientiert:</span><br/>" +
      "Als Unternehmen sind wir zutiefst zukunftsorientiert und engagieren uns für die Entwicklung innovativer Solarlösungen, die den sich ständig wandelnden Anforderungen unserer Welt gerecht werden. Wir investieren kontinuierlich in Forschung und Entwicklung, um Spitzenleistungen zu erzielen und unseren Kunden stets die neuesten Technologien und Trends bieten zu können.<br/>" +
      "<br/><br/>" +
      "<span>Professionell:</span><br/>" +
      "Unsere Professionalität spiegelt sich in jedem Aspekt unseres Geschäfts wider. Von der ersten Beratung bis zur Umsetzung eines Projekts stehen wir für höchste Standards in Bezug auf Effizienz, Transparenz und Kundenzufriedenheit. Unsere professionelle Herangehensweise gewährleistet, dass unsere Kunden Vertrauen in die Qualität unserer Arbeit haben und sich darauf verlassen können, dass wir ihre Erwartungen übertreffen.<br/>" +
      "<br/>" +
      "Mit dieser Unternehmensphilosophie streben wir danach, eine nachhaltige Zukunft zu schaffen und unseren Kunden dabei zu helfen, den Übergang zu sauberer Energie reibungslos und effektiv zu gestalten."
    },
    teams: {
      title: "Unser Team"
    }
  },
  agbPage: {
    title: "Allgemeine Geschäftsbedingungen MegaWatt Solar AG (Schweiz)"
  },
  referenzenPage: {
    categories: {
      all: "Alle",
      rooftop: "Aufdach",
      flatRoof: "Flachdach",
      enginnering: "Engineering"
    },
  }
};
