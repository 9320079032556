import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  DropdownHeaderContainer,
  DropdownHeaderIcon,
  DropdownHeaderTitle,
  DropdownText,
  TextDropdownContainer,
} from "./TextDropdown.styled";
import MinusIcon from "components/Icons/MinusIcon";
import PlusIcon from "components/Icons/PlusIcon";

const TextDropdown = (props) => {

  //GlobalToggleState variable represents the toggle that was clicked on

  const { globalToggleState, setGlobalToggleState, index } = props;
  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpened((prevState) => !prevState);
    setGlobalToggleState(index);
  };

  useEffect(() => {
    //Close dropdown when global state is changed
    if (globalToggleState !== index) {
      setIsOpened(false);
    }
  }, [globalToggleState, index]); //If there is a change in the global state or index, that useEffect will react and set the local state of isOpened to false, closing the dropdown.

  useEffect(() => {
    if (containerRef.current && textRef.current) {
      if (isOpened) {
        console.dir(containerRef.current);
        console.dir(textRef.current);
        containerRef.current.style.height =
          66 + textRef.current.offsetHeight + "px";
      } else {
        containerRef.current.style.height = "46px";
      }
    }
  }, [isOpened]);

  return (
    <TextDropdownContainer ref={containerRef}>
      <DropdownHeaderContainer onClick={toggleDropdown}>
        <DropdownHeaderTitle $isOpened={isOpened}>
          {props?.title}
        </DropdownHeaderTitle>
        <DropdownHeaderIcon>
          {isOpened ? <MinusIcon /> : <PlusIcon />}
        </DropdownHeaderIcon>
      </DropdownHeaderContainer>
      <DropdownText ref={textRef}>{props?.text}</DropdownText>
    </TextDropdownContainer>
  );
};

TextDropdown.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  globalToggleState: PropTypes.number,
  setGlobalToggleState: PropTypes.func,
  index: PropTypes.number,
};

export default TextDropdown;
