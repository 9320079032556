import React from "react";
import i18n from "../i18nt/index";

// export const BASE_PAGE = "/";
// export const LOGIN_PAGE = "/login";
// export const REGISTER_PAGE = "/register";
// export const FORGOT_PASSWORD_PAGE = "/forgot-password";
// export const HOME_PAGE = "/";
// export const ERROR_PAGE = "/error-page";
// export const NOT_FOUND_PAGE = "/not-found";
export const AUTH_CALLBACK_PAGE = "/api/auth/:provider/callback";
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SolarPowerOutlinedIcon from '@mui/icons-material/SolarPowerOutlined';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import Groups2Icon from '@mui/icons-material/Groups2';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ContactsIcon from '@mui/icons-material/Contacts';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';

export const PAGES = {
  BASE: {
    route: "/",
    title: i18n.t("pages.home"),
  },
  HOME: {
    route: "/home/*",
    title: i18n.t("pages.home"),
  },
  REGISTER: {
    route: "/register/*",
    title: i18n.t("pages.register"),
    nestedRoutes: {
      FIRST_STEP: {
        route: "/register/first-step/*",
        title: i18n.t("pages.register.nestedRoutes.firstStep"),
      },
      SECOND_STEP: {
        route: "/register/second-step/*",
      },
    },
  },
  LOGIN: {
    route: "/login/*",
    title: i18n.t("pages.login"),
  },
  FORGOT_PASSWORD: {
    route: "/forgot-password/*",
    title: i18n.t("pages.forgotPassword"),
  },
  ERROR: {
    route: "/error-page",
    title: i18n.t("pages.error"),
  },
  NOT_FOUND: {
    route: "/not-found",
    title: i18n.t("pages.notFound"),
  },
  PROFILE: {
    route: "/profile",
    title: i18n.t("pages.profile"),
  },
  SETTINGS: {
    route: "/settings",
    title: i18n.t("pages.settings"),
  },
  DASHBOARD: {
    route: "/dashboard",
    title: i18n.t("pages.dashboard"),
  },
  CONTACT: {
    icon: <ContactsOutlinedIcon />,
    hoverIcon: <ContactsIcon />,
    route: "/contact",
    title: i18n.t("pages.contact"),
  },
    PHOTOVOLTAIC: {
    icon: <SolarPowerOutlinedIcon />,
    hoverIcon: <SolarPowerIcon />,
    route: "/photovoltaic",
    title: i18n.t("pages.photovoltaic"),
  },
  IMPRESSUM: {
    icon: <FingerprintIcon />,
    route: "/impressum",
    title: i18n.t("pages.impressum"),
  },
  REFERENZEN: {
    route: "/referenzen",
    title: i18n.t("pages.referenzen"),
  },
  AGB: {
    route: "/agb",
    title: i18n.t("pages.agb"),
  },
  SERVICES: {
    icon: <HomeRepairServiceOutlinedIcon />,
    hoverIcon: <HomeRepairServiceIcon />,
    route: "/services",
    title: i18n.t("pages.services"),
  },
  TEAM: {
    icon: <Groups2OutlinedIcon />,
    hoverIcon: <Groups2Icon />,
    route: "/team",
    title: i18n.t("pages.team"),
  },
};
