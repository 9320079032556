import { Box } from "@mui/material";
import styled, { css } from "styled-components";

export const PhotoVoltaicComponentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 70px 40px;
  background-image: ${(props) => `url(${props?.$bgImage})`};
  background-color: ${(props) =>
    props?.theme?.colors?.componentBackgroundColor1};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  justify-content: center;
  position: relative;
  align-items: center;
  gap: 70px;
  ${(props) =>
    props?.$light &&
    css`
      background-color: ${(props) => props?.theme?.colors?.whiteColor};
    `}
  & > div {
    opacity: 0;
    position: relative;
    bottom: -10px;
    transition-delay: 0.1s;
    transition-duration: 0.5s;
    ${(props) =>
      props?.$shouldShowText &&
      css`
        bottom: 0;
        opacity: 1;
      `}
  }
  @media (max-width: 1200px) {
    gap: 40px;
  }
  @media (max-width: 959px) {
    padding: 40px 30px;
  }
`;

export const PhotoVoltaicComponentTitle = styled(Box)`
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: #d5ad00;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  color: ${(props) => props?.theme?.colors?.fontColor};
  line-height: 1.1;
  font-size: 36px;
  @media (max-width: 959px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  gap: 70px;
  max-width: 1200px;
  width: 100%;
  flex-direction: ${(props) => (props?.$reverse ? "row-reverse" : "row")};
  @media (max-width: 1200px) {
    gap: 40px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
export const ImageContainer = styled(Box)`
  flex: 1;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const ParagraphContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;
export const ParagraphTitle = styled(Box)`
  font-weight: 700;
  letter-spacing: 1px;
  color: ${(props) => props?.theme?.colors?.fontColor};
  line-height: 1.1;
  font-size: 22px;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
export const ParagraphText = styled(Box)`
  font-size: 18px;
  color: ${(props) => props?.theme?.colors?.fontColor};
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
`;
