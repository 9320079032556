import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ContentContainer,
  Image,
  ImageContainer,
  ParagraphContainer,
  ParagraphText,
  ParagraphTitle,
  PhotoVoltaicComponentContainer,
  PhotoVoltaicComponentTitle,
} from "./PhotoVoltaicComponent.styled";
import TextDropdown from "components/Dropdowns/TextDropdown/TextDropdown";
import { useLocation } from "react-router-dom";

const PhotoVoltaicComponent = (props) => {
  const containerRef = useRef(null);
  const [shouldShowText, setShouldShowText] = useState(false);
  const [globalToggleState, setGlobalToggleState] = useState(null);
  const location = useLocation();

  const scrollListener = (event) => {
    let scrollTop = event?.target?.scrollingElement?.scrollTop;
    let clientHeight = event?.target?.scrollingElement?.clientHeight;
    let offsetTop = containerRef?.current?.offsetTop;
    let scrollY = window.scrollY + window.innerHeight;
    let shouldShowText;
    if (event) shouldShowText = scrollTop + clientHeight > offsetTop;
    else shouldShowText = scrollY > offsetTop;
    setShouldShowText(shouldShowText);
  };

  useEffect(() => {
    if (location.hash?.includes?.(props?.id))
      window.scrollTo({
        top: containerRef?.current?.offsetTop - 134,
        behavior: "smooth",
      });
  }, [location]);

  useEffect(() => {
    scrollListener();
  }, []);

  useEffect(() => {
    if (!shouldShowText) window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, [containerRef.current, shouldShowText]);

  return (
    <PhotoVoltaicComponentContainer
      $bgImage={props?.bgImage}
      ref={containerRef}
      $shouldShowText={shouldShowText}
      $light={props?.light}
    >
      {props?.title && (
        <PhotoVoltaicComponentTitle>{props?.title}</PhotoVoltaicComponentTitle>
      )}
      <ContentContainer $reverse={props?.reverse}>
        <ParagraphContainer>
          <ParagraphTitle>{props?.paragraphTitle}</ParagraphTitle>
          <ParagraphText>{props?.paragraphText}</ParagraphText>
          {props?.dropDownData.map((dropdown, index) => (
            <TextDropdown
              key={index}
              title={dropdown.dropdownTitle}
              text={dropdown.dropdownText}
              globalToggleState={globalToggleState}
              setGlobalToggleState={setGlobalToggleState}
              index={index}
            />
          ))}
        </ParagraphContainer>
        <ImageContainer>
          <Image src={props?.imgSrc} />
        </ImageContainer>
      </ContentContainer>
      {props?.componentAddon}
    </PhotoVoltaicComponentContainer>
  );
};

PhotoVoltaicComponent.propTypes = {
  bgImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  paragraphTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  paragraphText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  id: PropTypes.string,
  light: PropTypes.bool,
  reverse: PropTypes.bool,
  imgSrc: PropTypes.string,
  componentAddon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  dropDownData: PropTypes.array,
};

export default PhotoVoltaicComponent;
