import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  AutoSlideshowContainer,
  AutoSlideshowSingleContainer,
} from "./AutoSlideshow.styled";

const AutoSlideshow = (props) => {
  const slideshowElements = useMemo(() => {
    let newSlideshowElements = props?.slideshowElements;
    while (newSlideshowElements?.length < 8) {
      newSlideshowElements = [...newSlideshowElements, ...newSlideshowElements];
    }
    return newSlideshowElements?.map?.((singleElement, index) => ({
      ...singleElement,
      index,
    }));
  }, [props?.slideshowElements]);
  const [currentInMotion, setCurrentInMotion] = useState(
    Array(6)
      .fill(0, 0)
      .map?.((singleElement, index) => index)
  );

  const changeElements = () => {
    let firstElementIndexInArray = currentInMotion?.[0];
    let nextElements = slideshowElements?.slice(
      firstElementIndexInArray + 1,
      firstElementIndexInArray + 7
    );
    if (nextElements?.length !== 6) {
      nextElements = [
        ...nextElements,
        ...slideshowElements.slice(0, 6 - nextElements?.length),
      ];
    }
    setCurrentInMotion(
      nextElements?.map?.((singleElement) => singleElement?.index)
    );
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      changeElements();
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentInMotion]);
  return (
    <AutoSlideshowContainer>
      {slideshowElements?.map?.((singleElement, index) => (
        <AutoSlideshowSingleContainer
          to={singleElement?.href}
          key={index}
          $positionIndex={currentInMotion?.findIndex(
            (singleElementIndex) => singleElementIndex === index
          )}
        >
          {singleElement?.icon}
        </AutoSlideshowSingleContainer>
      ))}
    </AutoSlideshowContainer>
  );
};

AutoSlideshow.propTypes = {
  children: PropTypes.node,
  slideshowElements: PropTypes.array,
};

export default AutoSlideshow;
