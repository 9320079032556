import { Box } from "@mui/material";
import ContactSection from "components/Footer/ContactSection/ContactSection";
import {
  FooterItemsContainer,
  FooterTitle,
} from "components/Footer/Footer.styled";
import { SocialLinkContainer } from "components/SocialHeader/SocialLink/SocialLink.styled";
import styled from "styled-components";

export const ContactDetails = styled(ContactSection)`
  align-self: center;
  & ${FooterTitle} {
    display: none;
  }
  & ${FooterItemsContainer}:nth-child(2) {
    & ${SocialLinkContainer} {
      color: ${(props) => props?.theme?.colors?.fontColor};
      font-weight: 300;
      font-size: 18px;
    }
    & ${SocialLinkContainer}:nth-child(1) {
      font-weight: 500;
    }
  }
  @media (max-width: 600px) {
    align-self: flex-start;
    & ${FooterItemsContainer}:nth-child(3) {
      & ${SocialLinkContainer} {
        font-weight: 300;
        font-size: 16px;
      }
      & ${SocialLinkContainer}:nth-child(2) {
        font-weight: 300;
        font-size: 18px;
      }
      & ${SocialLinkContainer}:nth-child(3) {
        display: none;
      }
    }
  }
`;
export const LogoImageContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  position: relative;
  top: -40px;
`;
export const LogoImage = styled.img`
  width: 20vw;
  @media (max-width: 959px) {
    width: 80vw;
  }
`;
