import React from "react";
import PropTypes from "prop-types";
import ButtonLink from "../ButtonLink/ButtonLink";
import { LINKEDIN_PAGE } from "constants/linkConstants";
import { LinkedinIcon } from "./LinkedinButtonLink.styled";

const LinkedinButtonLink = () => {
  return <ButtonLink icon={<LinkedinIcon />} to={LINKEDIN_PAGE} />;
};

LinkedinButtonLink.propTypes = {
  children: PropTypes.node,
};

export default LinkedinButtonLink;
