import { Box } from "@mui/material";
import styled from "styled-components";

export const ImageInfoDialogRow = styled(Box)`
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    font-size: 18px;
    @media (max-width: 600px) {
     font-size: 14px;
  }
`