import React from "react";
import PropTypes from "prop-types";
import { CTAButtonContainer } from "./CTAButton.styled";

const CTAButton = (props) => {
  return <CTAButtonContainer {...props}>{props?.children}</CTAButtonContainer>;
};

CTAButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default CTAButton;
