import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { ButtonLinkContainer } from "./ButtonLink.styled";

const ButtonLink = forwardRef((props, ref) => {
  return (
    <ButtonLinkContainer {...props} target="_blank" ref={ref}>
      {props?.icon}
    </ButtonLinkContainer>
  );
});

ButtonLink.displayName = "ButtonLink";

ButtonLink.propTypes = {
  icon: PropTypes.node,
};

export default ButtonLink;
