import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import { hexToRGB } from "util/helpers/colorHelpers";

export const ArrowContainer = styled(Box)`
  width: ${(props) => props?.$width};
  height: ${(props) => props?.$width};
  border: 1px solid
    ${(props) => hexToRGB(props?.theme?.colors?.whiteColor, 0.5)};
  border-radius: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  & svg {
    width: 10px;
    height: 20px;
    ${(props) =>
      props?.$left &&
      css`
        transform: rotate(180deg);
      `}
    & polyline {
      stroke: ${(props) => hexToRGB(props?.theme?.colors?.whiteColor, 0.5)};
    }
  }
  &:hover {
    border: 1px solid
      ${(props) => hexToRGB(props?.theme?.colors?.whiteColor, 1)};
    & polyline {
      stroke: ${(props) => hexToRGB(props?.theme?.colors?.whiteColor, 1)};
    }
  }
`;
