import { Box } from "@mui/material";
import { ArrowContainer } from "components/Arrow/Arrow.styled";
import Link from "components/Link/Link";
import styled, { css } from "styled-components";
import { hexToRGB } from "util/helpers/colorHelpers";

export const BannerTitle = styled(Box)`
  font-size: 3.2vw;
  font-weight: 700;
  & > pre {
    margin: 0;
  }
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: ${(props) => props?.theme?.colors?.whiteColor};
  @media (max-width: 959px) {
    font-size: 4vw;
  }
  @media (max-width: 600px) {
    font-size: 28px;
    white-space: pre-wrap;
    width: 70vw;
    & > span {
      display: block;
      font-size: 28px;
      white-space: pre-wrap;
      width: 70vw;
    }
    & br {
      display: none;
    }
  }
`;

export const AllBannersContainer = styled(Box)`
  height: calc(100vh - 144px);
  width: 100%;
  position: relative;
  z-index:100;
  ${(props) =>
    props?.$smaller &&
    css`
      height: calc(25vw - 34px);
    `}
  @media (max-width: 959px) {
    height: 572px;
    ${(props) =>
      props?.$smaller &&
      css`
        height: 366px;
        & ${BannerTitle} {
          position: absolute;
          top: 46%;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      `}
  }
  @media (max-width: 600px) {
    height: calc(100vh - 69px);
    ${(props) =>
      props?.$smaller &&
      css`
        height: 266px;
        & ${BannerTitle} {
          position: absolute;
          top: 46%;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      `}
  }
`;

export const BannerContainer = styled(Box)`
  height: 100%;
  width: 100%;
  padding: 0 15px;
  background-image: ${(props) => `url(${props?.$bgImage})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition-duration: 1s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  @media (max-width: 959px) {
    & ${ArrowContainer} {
      display: none;
    }
    padding: 0 15vw;
  }
  ${(props) =>
    props?.$isShown &&
    css`
      opacity: 1;
    `}
  ${(props) =>
    props?.$centerAlign &&
    css`
      justify-content: center;
      text-align: center;
    `}
`;
export const BannerContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 70vw;
`;

export const SlideshowChooserContainer = styled(Box)`
  position: absolute;
  bottom: 120px;
  width: fit-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 12px;
  align-items: center;
  @media (max-width: 600px) {
    bottom: 35px;
  }
`;
export const SlideshowDot = styled(Box)`
  border: 1px solid
    ${(props) => hexToRGB(props?.theme?.colors?.whiteColor, 0.4)};
  border-radius: 100%;
  width: 7px;
  height: 7px;
  cursor: pointer;
  background-color: ${(props) =>
    props?.$current
      ? hexToRGB(props?.theme?.colors?.whiteColor, 0.4)
      : "transparent"};
`;
export const BannerBottomNavigationContainer = styled(Box)`
  display: flex;
  align-items: stretch;
  position: absolute;
  bottom: -98px;
  width: 34%;
  z-index: 3;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  box-shadow: 3px 3px 15px 5px
    ${(props) => hexToRGB(props?.theme?.colors?.blackColor, 0.3)};
  @media (max-width: 959px) {
    width: 50%;
    bottom: -75px;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;
export const BannerBottomNavigationSeparator = styled(Box)`
  height: 100%;
  width: 1px;
`;
export const BannerBottomNavigationItemContainer = styled(Link)`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  flex: 1;
  cursor: pointer;
  border-left: 1px solid
    ${(props) => props?.theme?.colors?.separatorBannerColor};
  &:hover {
    & path {
      stroke: ${(props) => props?.theme?.colors?.yellowColor};
    }
    & line {
      stroke: ${(props) => props?.theme?.colors?.yellowColor};
    }
  }
  @media (max-width: 959px) {
    gap: 20px;
    padding: 30px 20px;
  }
`;
export const BannerBottomNavigationTitle = styled(Box)`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  flex: 1;
  text-transform: uppercase;
  color: ${(props) => props?.theme?.colors?.blackTextColor};
  line-height: 1.5;
  letter-spacing: 0.5px;
  @media (max-width: 959px) {
    font-size: 12px;
  }
`;
export const BannerBottomNavigationIcon = styled(Box)`
  width: 28%;
  margin: 0 auto;
  & svg {
    height: auto;
    width: 100%;
  }
  @media (max-width: 959px) {
    width: 40%;
  }
`;
