export default {
  app: {
    title: "React template",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don`t you will be logged out.",
  },
  common: {
    language: "Language",
    english: "English",
    german: "German",
    serbian: "Serbian",
    close: "Close",
    dataGridExample: "Data Grid Example",
    trademark: "TM",
    search: "Search",
    error: "Error",
    continue: "Continue",
    labelUsername: "Username",
    labelEmail: "Email",
    labelPassword: "Password",
    next: "Next",
    nextPage: "Next page",
    previousPage: "Previous page",
    back: "Back",
    goBack: "Go Back",
    ok: "Ok",
    done: "Done",
    confirm: "Confirm",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
  },
  notFound: {
    text: "We`re sorry but we couldn`t find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We`re sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  menu: {
    menu: "Menu",
  },
  pages: {
    contact: "Contact",
    photovoltaic: "Photovoltaic",
    impressum: "Impressions",
    services: "Services",
    team: "Team",
  },
  contact: {
    title: "Contact",
  },
  navigation: {
    title: "Navigation",
  },
  informations: {
    title: "Information",
  },
  header: {
    logoText: "THE SUN DELIVERS, WE SAVE",
  },
  bottomNavigation: {
    item1: {
      title: "Rooftop",
    },
    item2: {
      title: "Indach",
    },
    item3: {
      title: "Flat roof",
    },
    item4: {
      title: "Solarchitecture",
    },
  },
  homePage: {
    banner: {
      items: {
        text1: `Professional<br/>
          photovoltaic systems`,
        button1: "To the photovaltics",
        text2: `Our solar world -<br/>
          everything you want to know`,
        button2: "To the solar world",
        text3: "Future, mobile, independent.",
        button3: "We are happy to advise you",
        text4: `Valuable solar energy<br/>
          around the clock`,
        button4: "We are happy to advise you",
      },
    },
    paragraph1: {
      title: "Gain sustainable <br />electricity with the right <br />solution.",
      text: "Produce your own electricity now! Photovoltaics is the most worthwhile investment in a sustainable energy future. Save money by using your own energy. 4 reasons for a personal solar power plant <ul><li>Increase the value of your property</li><li>State subsidies</li> <li>Contribute to sustainability</li> <li>Reduce your electricity costs</li></ul>",
      buttonText: "More about us",
    },
    paragraph2: {
      title: "Our services",
      text: `Solar energy is undoubtedly a valuable resource that can be used efficiently. We offer comprehensive advice and support on the way to becoming an electricity producer and
        support you in maximizing the ecological added value through consumption optimization and energy storage. Through the implementation of
        building automation and continuous support during and after implementation, we enable you to realize your project smoothly`,
      littleParagraphs: {
        paragraph1: {
          title: "Planning & Consulting",
          text: `With our knowledge and experience, we develop the individual and perfect solution for you and your project`,
        },
        paragraph2: {
          title: "Installation",
          text: `We are passionate craftsmen and are happy to take care of the professional installation of your system to the highest standards. We coordinate with interfaces and ensure that the project runs smoothly`,
        },
        paragraph3: {
          title: "Smart building technology",
          text: `The efficient use of energy is crucial for the economic viability of a system. By using your electricity locally and in line with demand, you can make optimum use of it`,
        },
        paragraph4: {
          title: "Maintenance & Service",
          text: `We are also available to our customers after implementation and guarantee smooth operation of your system`,
        },
      },
      buttonText: "About our services",
    },
    paragraph3: {
      buttonText: "To the solar calculator",
      title: `Immerse yourself in the world of photovoltaics`,
      text: `In our company, aesthetics and functionality merge to create unique solutions. Let
        You can benefit from our many years of experience, our passion and our technological expertise.
        Convincing innovations that provide individual solutions for your project. Experience the full spectrum of performance, a variety of colors, shapes and materials. Use the surfaces in subtle or eye-catching ways and discover new possibilities`,
    },
    paragraph4: {
      buttonText: "To smart home technology",
      title: "Smart home technology",
      text: `The integration of smart home technology can significantly increase the utility value of solar power. Intelligent control allows you to exploit the full potential of your photovoltaic system regardless of the time of day. Choosing the right energy storage system enables maximum use, even with low solar radiation. <br /> <br />You can increase your self-sufficiency by using stationary batteries or accumulators from electric vehicles. We would be happy to discuss your specific requirements with you and develop a tailor-made concept to achieve the maximum benefit from the energy of your photovoltaic system`,
      iconTitle1: "Storage solutions",
      iconTitle2: "Hot water",
      iconTitle3: "E-Mobility",
      iconTitle4: "ZEV",
    },
    paragraph5: {
      title: "Our references",
      buttonText: "All references",
    },
    paragraph6: {
      title: "Our partners",
      text: "We build on constructive cooperation with our partners, for your best possible result.",
    },
    paragraph7: {
      title: "We are happy to advise you!",
      text: "We are happy to answer all your questions about photovoltaics, from planning permission to installation or maintenance.",
      buttonText: "Contact",
    },
  },
  impressumPage: {
    title: "Imprint",
    paragraph1: {
      title: "Overall responsibility"
    },
    paragraph2: {
      title: "Concept & Design"
    },
    paragraph3: {
      title: "Programming"
    },
  },
  contactPage: {
    banner: {
      title: "The energy<br />of the future is local.",
    },
    paragraph1: {
      title: "Get in touch with us. <br />We are at your disposal.",
    },
  },
  photovoltaicPage: {
    banner: {
      title: "The future <br />lies in the building envelope.",
    },
    paragraph1: {
      title: "Photovoltaic systems",
      text: "In photovoltaics (PV), solar radiation is converted directly and emission-free into electrical energy in solar cells. More and more countries are relying on environmentally friendly and cheap electricity from the sun. In Switzerland, the solar radiation on the total area is 200 times more than is used. PV systems are proven and forward-looking technologies for generating electricity. Use the potential of SOLAR energy.",
    },
    paragraph2: {
      title: "Indach",
      subtitle: "New construction & roof renovations",
      text: "The photovoltaic modules can be perfectly integrated into the roof. It is important to match the color, shape and structure to the roof characteristics, supplement empty areas with dummy modules and create a harmonious overall appearance. Systems can also be optimally integrated into the appearance of the building, taking into account the preservation of historical monuments.",
      dropDownData: [
        {
          dropdownTitle: "Roof renovation",
          dropdownText: "With our long-standing specialist partners, we support you in the renovation of your roof. We combine knowledge of photovoltaics with that of the building envelope. Take sustainable measures so that you can benefit from attractive subsidies."
        },
      ],
    },
    paragraph3: {
      title: "Rooftop",
      subtitle: "For every roof!",
      text: "On existing roofs, PV systems are mounted on the existing roofing. Black components are predominantly used to achieve a calm and inconspicuous appearance. Roof windows, chimneys and vapor pipes are framed and integrated with blind elements.",
      dropDownData: [
        {
          dropdownTitle: "Downloads",
          dropdownText: "follow",
        },
      ],
    },
    paragraph4: {
      title: "Flat roof",
      subtitle: "EFH to industrial plant",
      text: "Flat roofs can be optimally used for PV systems. Both the inclination and orientation of the modules can be optimized according to requirements. Every roof surface can be utilized to the maximum.",
      dropDownData: [
        {
          dropdownTitle: "Commercial building",
          dropdownText: "Cost savings through greater energy efficiency. Produce electricity more cheaply than buying it from the grid operator. And improve the CO2 balance and profitability of your business. <br /> <br />Make an important contribution to the future of energy. As a progressive and modern company, you have a major influence on achieving the goal of a climate-neutral Switzerland by 2050."
        },
      ],
    },
    paragraph5: {
      title: "Solarchitecture",
      subtitle: "A solution for every requirement!",
      text: "In addition to roofs, façades, retaining walls and carports are also perfect for generating electricity. With our many years of experience, we develop solutions that are aesthetically and technically convincing and suitable for the area of application.<br /><br />Build with PV and create more than a simple combination of architecture and technology: efficient and architecturally harmonious at the same time.",
      dropDownData: [
        {
          dropdownTitle: "Downloads",
          dropdownText: "follow",
        },
      ],
    },
  },
  servicesPage: {
    banner: {
      title: "Your project - our expertise.",
    },
    paragraph1: {
      title: "Services",
      text: "We have been successfully planning, installing and maintaining photovoltaic systems since 2023. We offer comprehensive support with implementation as well as engineering, tendering and specialist site management. We would be happy to advise you on which of our services best suits you and your project. ",
    },
    paragraph2: {
      title: "Our customers",
      subtitle: "Planning & Consulting",
      text: "Our company offers comprehensive planning and consulting services for building owners, architects and entire companies. We analyze the potential of a property in order to develop an optimal overall concept. In doing so, we take into account your individual needs and electricity consumption in order to adapt the building technology accordingly. Our aim is to create an economically interesting concept with aesthetic solutions. ",
      dropDownData: [
        {
          dropdownTitle: "Grants & subsidies",
          dropdownText:
          "We take care of the necessary permits, know what is needed and in what form. We are in contact with the building authorities and energy supply companies for this purpose.<br /><br /> Secure the one-off payment from the federal government and individual subsidies from the canton and municipality. We will take care of the necessary applications for you. Here you will find an overview of all subsidies, including tax relief.",
        },
      ],
    },
    paragraph3: {
      title: "Our customers",
      subtitle: "Installation & execution",
      text: "Our specialist staff are characterized by their attention to detail and the utmost care when it comes to installation and execution. Installation on the property requires optimum coordination of the details. Based on careful planning, the tinsmith and roofing work is synchronized. The electrical components of the photovoltaic system, such as module mounting, inverters and storage units, are commissioned professionally.",
      dropDownData: [
        {
          dropdownTitle: "Interface coordination",
          dropdownText: "We take over the management on site for our customers and coordinate interfaces between the trades and craftsmen. We ensure that the project runs smoothly.",
        },
        {
          dropdownTitle: "Fall protection",
          dropdownText: "Guaranteed safe and accident-free inspection of the system. Professional planning, installation and maintenance. In accordance with the latest SUVA norms and standards to protect the lives and health of our employees.",
        },
        {
          dropdownTitle: "Specialized personnel",
          dropdownText: "We train our employees and keep them up to date. This enables us to meet our high quality standards. ",
        },
      ],
    },
    paragraph4: {
      title: "Our customers",
      subtitle: "Smart home technology",
      text: "By integrating smart home technology, you can use your local energy efficiently. Innovative storage solutions and the control of sustainably generated electricity enable you to maximize your own consumption and yield. You can become more independent through the intelligent use of solar power.",
      dropDownData: [
        {
          dropdownTitle: "E-mobility",
          dropdownText: "Fill up on sunshine! Drive into an environmentally friendly future and charge your local electricity. You can increase your level of self-sufficiency with stationary batteries or accumulators from electric vehicles. Control, monitor and optimize your electricity consumption.",
        },
        {
          dropdownTitle: "ZEV",
          dropdownText: "Combination of own consumption <br /><br /> " +
            "More efficient buildings in the future by covering electricity requirements through self-consumption. As the owner, make locally produced solar power available to all residents. Benefit from a profitable concept and the tenants from cheaper electricity than electricity at the grid rate.",
        },
      ],
    },
    paragraph5: {
      title: "Our customers",
      subtitle: "Maintenance & aftercare",
      text: "Our professional monitoring system guarantees reliable monitoring for professionally built systems. We ensure long-term efficiency through continuous monitoring. It is also advisable to carry out a condition analysis of older systems and draw up a maintenance and modernization concept to optimize their efficiency. ",
      dropDownData: [
        {
          dropdownTitle: "System monitoring",
          dropdownText:
          "Remote monitoring is possible by means of monitoring. Yield controls as well as electricity and self-consumption can be visualized. These visualizations of the storage and smart grid components allow us to identify their optimization potential and quickly rectify failures."
        },
        {
          dropdownTitle: "Maintenance and cleaning",
          dropdownText:
          "Optimum yields can be ensured and smooth operation of the system guaranteed:<br /><br />" +
          "Checking the installation" +
          "Measurements of the functionality of the modules" +
          "Checking the electrical connections" +
          "Cleaning as required",
        },
      ],
    },
  },
  teamPage: {
    banner: {
      title: "Solar expertise<br/>under one roof.",
    },
    paragraph1: {
      title: "Our corporate philosophy: Energy for a sustainable future",
      text: "At MegaWatt Solar AG, we pride ourselves on combining extensive experience in the solar industry with a passionate commitment to sustainable energy production. Our roots go deep into the history of renewable energy, and we have always strived to provide innovative solutions to create a greener future."
    },
    paragraph2: {
      text: "<span>Experience:</span><br/>" +
      "Our experience in the solar industry forms the foundation of our knowledge and skills. We have successfully implemented numerous projects, developing not only technical expertise but also a deep understanding of our customers` needs. This experience enables us to offer customized solar solutions that meet the highest quality standards.<br/>" +
      "<br/><br/>" +
      "<span>Passion:</span><br/>" +
      "Our passion for clean energy drives us to do our best every day. At MegaWatt Solar AG, we firmly believe that clean energy is not only a necessity, but also an opportunity to bring about positive change. This passion is reflected in our dedicated team, who are passionate about implementing sustainable projects."
    },
    paragraph3: {
      text: "<span>Future-oriented:</span><br/>" +
      "As a company, we are deeply forward-thinking and committed to developing innovative solar solutions that meet the ever-changing demands of our world. We continuously invest in research and development to achieve excellence and always offer our customers the latest technologies and trends.<br/>" +
      "<br/><br/>" +
      "<span>Professional:</span><br/>" +
      "Our professionalism is reflected in every aspect of our business. From the initial consultation to the implementation of a project, we stand for the highest standards of efficiency, transparency and customer satisfaction. Our professional approach ensures that our clients have confidence in the quality of our work and can rely on us to exceed their expectations.<br/>" +
      "<br/>" +
      "With this corporate philosophy, we strive to create a sustainable future and help our customers make the transition to clean energy smoothly and effectively."
    },
  },
};
