import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ArrowIcon,
  SlideshowContainer,
  SlideshowElementContainer,
  SlideshowImageContainer,
} from "./Slideshow.styled";

const Slideshow = (props) => {
  const [currentSlideshowElement, setCurrentSlideshowElement] = useState(
    props?.slideshowElements?.[0]
  );

  const changeSlideshowElement = (index) => {
    let newIndex = index;
    if (index < 0) newIndex = props?.slideshowElements?.length - 1;
    if (index >= props?.slideshowElements?.length) newIndex = 0;
    setCurrentSlideshowElement(props?.slideshowElements?.[newIndex]);
  };

  useEffect(() => {
    let timeout;
    if (props?.slideshowElements?.length > 1) {
      timeout = setTimeout(() => {
        changeSlideshowElement(currentSlideshowElement?.index + 1);
      }, 6000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [props?.slideshowElements, currentSlideshowElement]);

  return (
    <SlideshowContainer className={props?.className}>
      <ArrowIcon
        left
        onClick={() =>
          changeSlideshowElement(currentSlideshowElement?.index - 1)
        }
      />

      <SlideshowElementContainer>
        {props?.slideshowElements?.map?.((singleElement, index) => (
          <SlideshowImageContainer
            key={index}
            $bgImage={singleElement?.bgImage}
            $isShown={currentSlideshowElement?.index === index}
          />
        ))}
      </SlideshowElementContainer>
      <ArrowIcon
        onClick={() =>
          changeSlideshowElement(currentSlideshowElement?.index + 1)
        }
      />
    </SlideshowContainer>
  );
};

Slideshow.propTypes = {
  className: PropTypes.string,
  slideshowElements: PropTypes.arrayOf(
    PropTypes.exact({
      bgImage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.element,
      ]),
      index: PropTypes.number,
    })
  ),
};

export default Slideshow;
